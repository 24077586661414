import React, { useEffect } from 'react';

import querystring from 'query-string';

import { IFilter } from 'modules/arm2/models/types';
import WarehousesSelect from 'modules/warehouses/components/WarehousesSelect';

interface IProps {
  setFilter: (key: string, value: string[] | string) => void;
  getList: (filter?: IFilter) => void;
  value: string[] | string;
  className?: string;
  getFilterFromLocalStorage?: () => IFilter;
  defaultValue?: string[] | string;
  searchSet: (query: string) => void;
}

const WarehousesFilter = ({ setFilter, getList, value, className, searchSet }: IProps) => {
  useEffect(() => {
    const selectedWarehouses = querystring.parse(window.location.search).warehouse || [];
    const warehouseArray =
      typeof selectedWarehouses === 'string' ? [selectedWarehouses] : selectedWarehouses;

    setFilter('warehouses', warehouseArray);
  }, []);

  const handleFilter = (val): void => {
    searchSet('');
    const newValue = val.length > 0 ? val : [];
    setFilter('warehouses', newValue);
    getList();
  };

  return (
    <div className={className}>
      <WarehousesSelect
        onChange={handleFilter}
        value={value}
        popupMatchSelectWidth={false}
        maxTagCount={2}
      />
    </div>
  );
};

export default WarehousesFilter;
