import React from 'react';

import { observer } from 'mobx-react-lite';

import ListMobX from 'modules/directory/containers/List/List';
import { useStore } from 'services/store';

import { getColumns } from './helpers';

interface IProps {
  baseUrl: string;
}

const ShopsList = ({ baseUrl }: IProps) => {
  const { shops } = useStore();

  return (
    <ListMobX
      baseUrl={baseUrl}
      actionListGet={shops.getList}
      list={shops.list}
      filter={shops.filter}
      setFilter={shops.setFilter}
      getColumns={getColumns}
      isLoading={shops.isLoadingList}
      actionDelete={shops.delete}
      actionSearch={shops.search}
    />
  );
};

export default observer(ShopsList);
