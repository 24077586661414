import './style.less';

import React from 'react';

import { observer } from 'mobx-react-lite';

import Form from 'modules/directory/containers/Form';
import { translate } from 'modules/localization';
import { useStore } from 'services/store';

import { getDataToSet, prepareData } from './helpers';
import LinesForm from './LinesForm';

interface IOwnProps {
  baseUrl: string;
}

const FormWrapper = ({ baseUrl }: IOwnProps) => {
  const { lines, core, coverage, warehouses, couriers, transport, deliveryMethods } = useStore();

  const renderFields = (form) => {
    return <LinesForm form={form} />;
  };

  return (
    <Form
      baseUrl={baseUrl}
      actionCreate={lines.create}
      actionEdit={lines.edit}
      actionGet={lines.get}
      getDataToSet={getDataToSet}
      getFields={() => ({})}
      prepareData={prepareData}
      message={{
        editSuccess: translate('linesEditSuccess'),
        createSuccess: translate('linesCreateSuccess'),
        editTitle: translate('linesEditTitle'),
        createTitle: translate('linesCreateTitle'),
      }}
      isPermittedSubmit={core.permissions['lines.create'] && core.permissions['lines.update']}
      isLoading={
        lines.isLoadingForm ||
        coverage.isLoadingCoveragesList ||
        warehouses.isLoadingList ||
        couriers.isLoadingCouriersList ||
        transport.isLoadingList ||
        deliveryMethods.isLoadingList
      }
      renderFields={renderFields}
      modalClassName="lines-modal"
    />
  );
};

export default observer(FormWrapper);
