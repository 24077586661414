import './style.less';

import React from 'react';

import { SyncOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { observer } from 'mobx-react-lite';

import { useStore } from 'services/store';

const Refresh = () => {
  const { routeGenerator } = useStore();

  return (
    <Button
      className="rg-page__refresh"
      type="link"
      icon={<SyncOutlined />}
      disabled={
        routeGenerator.isLoadingTasksList ||
        routeGenerator.getIsLoadingCalculate ||
        routeGenerator.getIsLoadingCalculateYandex ||
        routeGenerator.getIsLoadingSave
      }
      onClick={() => routeGenerator.apiStore.getTasksWrapperLoading()}
    />
  );
};

export default observer(Refresh);
