import React from 'react';

import dayjs from 'dayjs';
import { toJS } from 'mobx';

import { LOCAL_STORAGE } from 'constants/index';
import { dateFormat } from 'helpers/string';
import { translate } from 'modules/localization';
import {
  IRouteDraft,
  ISavedRouteSettings,
  ISettingsData,
  ITaskForCompatibility,
  ITasksGroupCoverages,
} from 'modules/routeGenerator/models/types';
import {
  concurrenceCheck,
  groupTasksWithRelatives,
  ungroupTasksWithRelatives,
} from 'modules/tasks/helpers/lists';
import { ITask } from 'modules/tasks/models/types';
import { hasRelativeTasks } from 'modules/tasks/models/utils';

export interface IMultiDragAwareReorderArgs {
  tasksListSource: ITask[];
  routeDraftList: IRouteDraft[];
  selectedTaskGuids: React.Key[];
  destination: string;
}

export const getRouteDraftTaskList = ({
  tasksListSource,
  routeDraftList,
  selectedTaskGuids,
  destination,
}: IMultiDragAwareReorderArgs): ITask[] => {
  let newTasksListRouteDraft: ITask[] = [];
  if (destination !== 'new') {
    const index = routeDraftList.findIndex((item) => item.uid === destination);
    newTasksListRouteDraft = routeDraftList[index].tasksList;
  }
  const newTasksListSource = tasksListSource;
  for (let i = 0; i < newTasksListSource.length; i++) {
    if (concurrenceCheck(newTasksListSource[i], selectedTaskGuids)) {
      newTasksListRouteDraft.push(newTasksListSource[i]);
    }
  }

  return groupTasksWithRelatives(ungroupTasksWithRelatives(newTasksListRouteDraft));
};

export const tasksSortByTimeSlots = (tasks: ITask[]): ITask[] => {
  return tasks.sort((a, b) => {
    if (a.time_slot_start < b.time_slot_start) {
      return -1;
    } else if (a.time_slot_start > b.time_slot_start) {
      return 1;
    }
    if (a.time_slot_end < b.time_slot_end) {
      return -1;
    } else if (a.time_slot_end > b.time_slot_end) {
      return 1;
    }
    return 0;
  });
};

export const checkTaskForCompatibility = (
  tasks: ITask[],
  settingsData: ISettingsData,
): ITaskForCompatibility => {
  let error: string = null;
  const tasksCheckError: ITask[] = tasks.map((task) => {
    const problems: string[] = [];
    if (
      settingsData.transport &&
      Array.isArray(settingsData.transport.warehouses) &&
      !settingsData.transport.warehouses.some((item) => item.guid === task.warehouse_guid)
    ) {
      problems.push(translate('transportIsNotCompareToWarehouse'));
      error = translate('thereAreTasksWithNotAvailableDeliveryMethodsForTransport');
    }
    if (
      settingsData.courier &&
      settingsData.courier.profile &&
      Array.isArray(settingsData.courier.profile.warehouses) &&
      !settingsData.courier.profile.warehouses.some((item) => item.guid === task.warehouse_guid)
    ) {
      problems.push(translate('courierIsNotCompareToWarehouse'));
      error = translate('thereAreTasksWithNotAvailableWarehouseForCourier');
    }
    if (
      settingsData.transport &&
      Array.isArray(settingsData.transport.delivery_methods) &&
      !settingsData.transport.delivery_methods.some(
        (item) => item.guid === task.delivery_method_guid,
      )
    ) {
      problems.push(translate('transportIsNotCompareToDeliveryMethod'));
      error = translate('thereAreTasksWithNotAvailableDeliveryMethodsForTransport');
    }
    return { ...task, problems };
  });

  return {
    tasks: tasksCheckError,
    error,
  };
};

export const GENERATOR_STORE_VERSION = '2';

export const getSavedGeneratorSettings = (): ISavedRouteSettings => {
  const settings = localStorage.getItem(LOCAL_STORAGE.SAVED_GENERATOR_SETTINGS);
  if (!settings) {
    return null;
  }
  const parsedSettings: ISavedRouteSettings = JSON.parse(settings);
  if (!parsedSettings.version || parsedSettings.version !== GENERATOR_STORE_VERSION) {
    return null;
  }
  if (parsedSettings.createdAt && parsedSettings.createdAt !== dayjs().format(dateFormat.search)) {
    localStorage.removeItem(LOCAL_STORAGE.SAVED_GENERATOR_SETTINGS);
    localStorage.removeItem(LOCAL_STORAGE.SAVED_ROUTE_DRAFTS);
    return null;
  }
  if (parsedSettings.deliveryDate) {
    return parsedSettings;
  }
  return null;
};

export const isRouteHasError = (routeDraft: IRouteDraft): boolean => {
  return Boolean(routeDraft.error);
};

export const isRouteReadyToCalculate = (routeDraft: IRouteDraft): boolean => {
  return (
    Boolean(routeDraft.courierGuid) &&
    routeDraft.tasksList.length !== 0 &&
    !isRouteHasError(routeDraft) &&
    Boolean(routeDraft.time)
  );
};

export const isSomeRoutesReadyToCalculate = (list: IRouteDraft[]): boolean => {
  return list.some(isRouteReadyToCalculate);
};

export const isSomeRoutesReadyForCalculate = (list: IRouteDraft[]): boolean => {
  return list.some((item) => !item.isCalculated && item.courierGuid && item.tasksList.length !== 0);
};

export const isSomeRoutesReadyForSave = (list: IRouteDraft[]): boolean => {
  return list.some((item) => item.isCalculated && item.courierGuid && item.tasksList.length !== 0);
};

export const isSomeRoutesReadyToSave = (list: IRouteDraft[]): boolean => {
  return !list.some(isRouteHasError);
};

export const isSomeRoutesLoading = (list: IRouteDraft[]): boolean => {
  return list.some((item) => item.isLoadingCard);
};

export const getTaskListForSaving = (tasksList: ITask[]): ITask[] => {
  return tasksList.reduce((list, task) => {
    return hasRelativeTasks(task)
      ? [
          ...list,
          ...task.relative_tasks.map((t) => ({
            guid: t.guid,
            planned_delivery_time: t.planned_delivery_time,
          })),
        ]
      : [
          ...list,
          {
            guid: task.guid,
            planned_delivery_time: task.planned_delivery_time,
          },
        ];
  }, []);
};

export const addErrorToTask = (task: ITask, targetGuid: string, error: string): ITask => {
  if (task.guid === targetGuid) {
    return { ...task, error: error };
  } else {
    return task;
  }
};

export const putErrorIntoTaskList = (list: ITask[], targetGuid: string, error: string): ITask[] => {
  return list.map((task) => {
    if (hasRelativeTasks(task)) {
      return {
        ...task,
        relative_tasks: task.relative_tasks.map((relativeTask) => {
          return addErrorToTask(relativeTask, targetGuid, error);
        }),
      };
    }
    return addErrorToTask(task, targetGuid, error);
  });
};

export const sortRoutesByCourierName = (routes: IRouteDraft[]): IRouteDraft[] => {
  const copyRoutes = [...toJS(routes)];
  return copyRoutes.sort((a, b) => {
    if (!b.fullName) {
      return 1;
    }
    if (!a.fullName) {
      return -1;
    }
    if (a.fullName < b.fullName) {
      return -1;
    }
    if (a.fullName > b.fullName) {
      return 1;
    }
    return 0;
  });
};

export const highlight = (list: ITask[], highlighted: string[]): ITask[] => {
  if (!highlighted || highlighted.length === 0) {
    return list;
  }

  let scrollableUsed = false;

  return list.map((task) => {
    const isHighlighted = hasRelativeTasks(task)
      ? task.relative_tasks.some(({ guid }) => highlighted.includes(guid))
      : highlighted.includes(task.guid);
    const isScrollableIntoView = isHighlighted && !scrollableUsed;

    if (isScrollableIntoView) {
      scrollableUsed = true;
    }

    if (hasRelativeTasks(task)) {
      const relative_tasks = task.relative_tasks.map((t) => {
        return { ...t, isHighlighted: highlighted.includes(t.guid) };
      });

      return { ...task, relative_tasks, isHighlighted, isScrollableIntoView };
    }

    return { ...task, isHighlighted, isScrollableIntoView };
  });
};

export const isAnyTaskHighlighted = (tasksList: ITask[]): boolean => {
  if (!tasksList || tasksList.length === 0) {
    return false;
  }

  return tasksList.some(({ isHighlighted }) => isHighlighted);
};

export const highlightCoverages = (
  coverages: { name: string; guid: string }[],
  tasksGrouped: ITasksGroupCoverages,
): { name: string; guid: string; isHighlighted: boolean; isScrollableIntoView?: boolean }[] => {
  let scrollableUsed = false;

  return coverages.map((cov) => {
    const isHighlighted = isAnyTaskHighlighted(tasksGrouped[cov.guid].tasks);
    const isScrollableIntoView = isHighlighted && !scrollableUsed;

    if (isScrollableIntoView) {
      scrollableUsed = true;
    }

    return { ...cov, isHighlighted, isScrollableIntoView };
  });
};

export const highlightRouteDrafts = (
  routes: IRouteDraft[],
  highlightedTasks: string[],
): IRouteDraft[] => {
  let scrollableUsed = false;

  return routes.map((route) => {
    const tasksList = highlight(route.tasksList, highlightedTasks);
    const isHighlighted = isAnyTaskHighlighted(tasksList);
    const isScrollableIntoView = isHighlighted && !scrollableUsed;

    if (isScrollableIntoView) {
      scrollableUsed = true;
    }

    return { ...route, tasksList, isHighlighted, isScrollableIntoView };
  });
};
