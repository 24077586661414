import './style.less';

import React, { useState } from 'react';

import { ExclamationCircleFilled } from '@ant-design/icons';
import { Button, DatePicker, Form, Modal } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { observer } from 'mobx-react-lite';

import { dateFormat } from 'helpers/string';
import DeliveryMethodsSelect from 'modules/delivery-methods/components/DeliveryMethodsSelect';
import { translate } from 'modules/localization';
import { GeneratorPageName, IRouteSettings } from 'modules/routeGenerator/models/types';
import WarehousesSelect from 'modules/warehouses/components/WarehousesSelect';
import { useStore } from 'services/store';

const { confirm } = Modal;

const RouteGeneratorCreateForm = () => {
  const { routeGenerator } = useStore();

  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();

  const handleSubmit = (values: IRouteSettings) => {
    setIsLoading(true);
    routeGenerator.setupRouteSettings(values);
    routeGenerator.removeAllRoutes();
    routeGenerator.setActivePage(GeneratorPageName.GENERATOR);
  };

  const showConfirm = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (routeGenerator.routeDraftList.length > 0) {
      confirm({
        title: translate('confirmDeleteRoutes'),
        icon: <ExclamationCircleFilled />,
        cancelText: translate('cancel'),
        okButtonProps: {
          className: 'rg_ok-button-confirm',
        },
        cancelButtonProps: {
          className: 'rg_ok-button-cancel',
        },
        onOk() {
          form.submit();
        },
      });
    } else {
      form.submit();
    }
  };

  return (
    <Form name="basic" form={form} onFinish={handleSubmit} layout="vertical" colon>
      <div className="route-generator-form">
        <Form.Item
          label={translate('delivery') + ':'}
          name="deliveryMethod"
          rules={[{ required: true, message: translate('requiredValidator') }]}
          data-testid="generator-deliveryMethodsInput"
        >
          <DeliveryMethodsSelect isSelectAllOptionAllowed mode="multiple" />
        </Form.Item>
        <div>
          <Form.Item
            label={translate('routeStartDate') + ':'}
            name="deliveryDate"
            rules={[{ required: true, message: translate('enterDate') }]}
          >
            <DatePicker
              placeholder={translate('date')}
              format={dateFormat.string}
              disabledDate={(current: Dayjs): boolean => {
                const newCurrent = current.clone();
                const diff = dayjs.duration(dayjs().endOf('day').diff(newCurrent.endOf('day')));
                return current && diff.asDays() > 0;
              }}
              getPopupContainer={(el) => el}
              data-testid="generator-deliveryDateInput"
            />
          </Form.Item>
        </div>

        <Form.Item
          label={translate('warehouse')}
          name="warehouses"
          rules={[{ required: true, message: translate('requiredValidator') }]}
          data-testid="generator-warehouseInput"
        >
          <WarehousesSelect mode={'multiple'} maxTagCount={1} showSearch />
        </Form.Item>
      </div>
      <div className="route-generator-form-footer">
        <Button
          loading={isLoading}
          htmlType="submit"
          key="save"
          onClick={showConfirm}
          type="primary"
        >
          {translate('create')}
        </Button>
      </div>
    </Form>
  );
};

export default observer(RouteGeneratorCreateForm);
