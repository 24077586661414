import React, { useEffect, useState } from 'react';

import { Form, FormInstance, Input } from 'antd';
import { observer } from 'mobx-react-lite';

import CouriersSelect from 'modules/couriers/components/directory/CouriersSelect';
import { ICourier } from 'modules/couriers/models/types';
import CoveragesSelect from 'modules/coverages/components/CoveragesSelect';
import DeliveryMethodsSelect from 'modules/delivery-methods/components/DeliveryMethodsSelect';
import { translate } from 'modules/localization';
import TransportSelect from 'modules/transports/components/TransportSelect';
import WarehousesSelect from 'modules/warehouses/components/WarehousesSelect';
import { useStore } from 'services/store';

interface IProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: FormInstance;
}

const LinesForm = ({ form }: IProps) => {
  const { warehouses, core, couriers } = useStore();
  const [warehouseGuid, setWarehouseGuid] = useState<string>(null);
  const [dmGuid, setDMGuid] = useState<string>(null);
  const [locationGuid, setLocationGuid] = useState<string>(null);
  const [isCourierLoading, setIsCourierLoading] = useState(false);
  const [courierData, setCourierData] = useState<ICourier>(null);
  const warehouseChangeTrigger = Form.useWatch('warehouse_guid', form);
  const dmChangeTrigger = Form.useWatch('delivery_method_guid', form);

  useEffect(() => {
    const courierValue = form.getFieldValue(['courier_guid']);
    const courierGuid = courierValue?.key || courierValue;
    if (courierGuid) {
      setupCourier(courierGuid);
    }
  }, [warehouseChangeTrigger]);

  useEffect(() => {
    const value = form.getFieldValue(['warehouse_guid']);
    const guid = value?.key || value;
    setWarehouseGuid(guid);
    if (guid) {
      warehouses.get(guid).then((data) => setLocationGuid(data.locations[0].guid));
    } else {
      setLocationGuid(null);
    }
  }, [warehouseChangeTrigger]);

  useEffect(() => {
    const value = form.getFieldValue(['delivery_method_guid']);
    const guid = value?.key || value;
    setDMGuid(guid);
  }, [dmChangeTrigger]);

  const setupCourier = async (courierGuid: string) => {
    if (courierGuid) {
      setIsCourierLoading(true);

      const courier = await couriers.apiStore.getCourier(courierGuid);
      setCourierData(courier);

      setIsCourierLoading(false);
    } else {
      return;
    }
  };

  const onWarehouseChange = (): void => {
    form.setFieldValue('courier_guid', null);
    setCourierData(null);
    form.setFieldValue('transport_guid', null);
    form.setFieldValue('delivery_method_guid', null);
    form.setFieldValue('coverage_list', null);
  };

  const onCourierChange = (courierGuid: string): void => {
    form.setFieldValue('transport_guid', null);

    if (!courierGuid) {
      setCourierData(null);
    } else {
      setupCourier(courierGuid);
    }
  };

  const onDmChange = (): void => {
    form.setFieldValue('coverage_list', null);
  };

  const isView = !core.permissions['lines.update'];

  return (
    <div>
      <Form.Item
        className="lines-form-item"
        label={translate('lineName')}
        name="name"
        rules={[{ required: true, message: translate('requiredValidator') }]}
        required
      >
        <Input placeholder={translate('enterLineName')} disabled={isView} />
      </Form.Item>
      <Form.Item
        className="lines-form-item"
        label={translate('warehouse')}
        name="warehouse_guid"
        rules={[{ required: true, message: translate('requiredValidator') }]}
        required
      >
        <WarehousesSelect
          optionLabelProp="children"
          mode={null}
          showSearch
          disabled={isView}
          onChange={onWarehouseChange}
        />
      </Form.Item>
      <Form.Item
        className="lines-form-item"
        label={translate('lineCourier')}
        name="courier_guid"
        rules={[{ required: true, message: translate('requiredValidator') }]}
        required
      >
        <CouriersSelect
          optionLabelProp="children"
          maxTagCount={'responsive'}
          warehouses={warehouseGuid && [warehouseGuid]}
          disabled={!warehouseGuid || isView}
          onChange={onCourierChange}
        />
      </Form.Item>
      <Form.Item
        className="lines-form-item"
        label={translate('transport')}
        name="transport_guid"
        rules={[{ required: true, message: translate('requiredValidator') }]}
        required
      >
        <TransportSelect
          optionLabelProp="children"
          disabled={isView || !courierData}
          mode={null}
          defaultOptions={courierData ? courierData.transports : null}
          loading={isCourierLoading}
        />
      </Form.Item>
      <Form.Item
        className="lines-form-item"
        label={translate('deliveryMethod')}
        name="delivery_method_guid"
        rules={[{ required: true, message: translate('requiredValidator') }]}
        required
      >
        <DeliveryMethodsSelect
          optionLabelProp="children"
          mode={null}
          disabled={!locationGuid || isView}
          onChange={onDmChange}
        />
      </Form.Item>
      <Form.Item
        className="lines-form-item"
        label={translate('coverages')}
        name="coverage_list"
        rules={[{ required: true, message: translate('requiredValidator') }]}
        required
      >
        <CoveragesSelect
          optionLabelProp="children"
          location={locationGuid}
          deliveryMethod={dmGuid}
          disabled={!locationGuid || isView}
          maxTagCount={'responsive'}
        />
      </Form.Item>
    </div>
  );
};

export default observer(LinesForm);
