import React from 'react';

import { observer } from 'mobx-react-lite';

import HintedText from 'modules/common/components/HintedText';
import { ArrowUpSvg } from 'modules/common/components/Icons';
import { ICoverage } from 'modules/coverages/models/types';

interface IProps {
  coverages: Partial<ICoverage>[];
  isAutoRoute: boolean;
  lineName?: string;
}

const Coverages = ({ coverages, isAutoRoute, lineName }: IProps) => {
  const coveragesNames = coverages.map((item) => (item && item.name ? item.name : '')).join(', ');
  const PopoverContent = (
    <>
      <b>
        {lineName && (
          <>
            {lineName}
            <br />
          </>
        )}
      </b>{' '}
      {coveragesNames ? coveragesNames : ''}
    </>
  );

  if (coverages.length === 0) {
    return (
      <div className="rg-routecard__coverages-wrapper">
        {isAutoRoute && <ArrowUpSvg />}
        <div className="rg-routecard__coverages">
          {lineName ? <HintedText content={PopoverContent}>&mdash;</HintedText> : <>&mdash;</>}
        </div>
      </div>
    );
  }

  return (
    <div className="rg-routecard__coverages-wrapper">
      {isAutoRoute && <ArrowUpSvg />}
      <div className="rg-routecard__coverages">
        <HintedText content={PopoverContent}>{coveragesNames}</HintedText>
      </div>
    </div>
  );
};

export default observer(Coverages);
