import React from 'react';

import { observer } from 'mobx-react-lite';

import Title from 'modules/common/components/Title';
import WarehouseFilter from 'modules/couriers/components/directory/WarehouseFilter';
import ButtonCreate from 'modules/directory/components/ActionButtons/ButtonCreate';
import ButtonCreateMobile from 'modules/directory/components/ActionButtons/ButtonCreateMobile';
import Search from 'modules/directory/containers/Search';
import { translate } from 'modules/localization';
import { useStore } from 'services/store';

interface IProps {
  baseUrl: string;
}

const CouriersHeader = ({ baseUrl }: IProps) => {
  const { core, courierDirectory } = useStore();
  return (
    <div className="dir__header">
      <Title className="dir__title" size={Title.SIZE.H3} weight={Title.WEIGHT.SEMIBOLD}>
        {translate('couriers')}

        {core.isMobile && (
          <ButtonCreateMobile baseUrl={baseUrl} isPermitted={core.permissions['couriers.create']} />
        )}
      </Title>
      <div className="dir__header-section">
        <div className="dir__header-filters">
          <WarehouseFilter
            className="courier-filter__warehouse"
            getList={courierDirectory.getList}
            setFilter={courierDirectory.setFilter}
            value={courierDirectory.filter.warehouses}
            searchSet={courierDirectory.setSearchQuery}
          />
          <Search
            actionSearch={courierDirectory.search}
            actionListGet={courierDirectory.getList}
            actionSearchSet={courierDirectory.setSearchQuery}
            query={courierDirectory.searchQuery}
            placeholder={translate('searchByFullName')}
          />
        </div>

        {!core.isMobile && (
          <ButtonCreate baseUrl={baseUrl} isPermitted={core.permissions['couriers.create']} />
        )}
      </div>
    </div>
  );
};

export default observer(CouriersHeader);
