export const ru_translations: Record<string, string> = {
  // sections & entities
  planning: 'Планирование',
  tariffing: 'Тарификация',
  directory: 'Справочник',
  logout: 'Выход',
  order: 'Заказ',
  orders: 'Заказы',
  ordersList: 'Список заказов',
  orderStatus: 'Статус заказа',
  orderDeliveryDateTime: 'Ожидаемое время',
  orderParameters: 'Параметры заказа',
  orderSizes: 'Параметры груза',
  orderType: 'Тип',
  orderDetails: 'Детали заказа',
  productView: 'Вид товара',
  taskTitle: 'Задание',
  tasks: 'Задания',
  tasksList: 'Список заданий',
  taskType: 'Тип задания',
  taskStatus: 'Статус задания',
  taskDeliveryDateTime: 'Ожидаемое время',
  taskPlannedDeliveryDateTime: 'Плановое время',
  taskRealDeliveryDateTime: 'Фактическое время',
  taskSizes: 'Параметры груза',
  removeTaskFromRoute: 'Убрать из маршрута',
  cancelTheTask: 'Отменить задание',
  taskCancelation: 'Отмена задания',
  finishTheTask: 'Завершить',
  route: 'Маршрут',
  routes: 'Маршруты',
  routesList: 'Список маршрутов',
  routeStatus: 'Статус маршрута',
  routeStartDate: 'Дата начала',
  routeGenerator: 'Генератор маршрутов',
  routePlannedDateTime: 'Плановое время',
  routeRealDateTime: 'Фактическое время',
  routeCreated: 'Маршрут создан',
  routeFormTitle: 'Формирование маршрута',
  routeCreateTitle: 'Создание маршрута',
  routeCalculated: 'Маршрут рассчитан',
  routeDelete: 'Удалить маршрут',
  addTasksToRoute: 'Добавить в маршрут',
  courier: 'Курьер',
  couriers: 'Курьеры',
  couriersList: 'Список курьеров',
  courierEditSuccess: 'Курьер успешно изменен',
  courierCreateSuccess: 'Курьер успешно создан',
  courierEditTitle: 'Редактирование курьера',
  courierCreateTitle: 'Добавление курьера',
  deliveryMethod: 'Способ доставки',
  deliveryMethods: 'Способы доставки',
  allDeliveryMethod: 'Все способы доставки',
  withoutDeliveryMethod: 'Без доставки',
  deliveryMethodEditSuccess: 'Способ доставки успешно изменен',
  deliveryMethodCreateSuccess: 'Способ доставки успешно создан',
  deliveryMethodEditTitle: 'Редактирование способа доставки',
  deliveryMethodCreateTitle: 'Добавление способа доставки',
  deliveryMethodsParams: 'Параметры способов доставки',
  deliveryMethodTitle: 'Название способа доставки',
  deliveryMethodCode: 'Код способа доставки',
  clientGroup: 'Группа клиентов',
  clientGroups: 'Группы клиентов',
  clientGroupEditSuccess: 'Группа клиентов успешно изменена',
  clientGroupCreateSuccess: 'Группа клиентов успешно создана',
  clientGroupEditTitle: 'Редактирование группы клиентов',
  clientGroupCreateTitle: 'Добавление группы клиентов',
  warehouse: 'Склад',
  warehouses: 'Склады',
  warehouseEdited: 'Склад успешно изменен',
  warehouseCreated: 'Склад успешно создан',
  warehouseEditTitle: 'Редактирование склада',
  warehouseCreateTitle: 'Добавление склада',
  warehouseHasNoCoords: 'Отсутствуют координаты склада',
  warehouseSettingsEdited: 'Настройки склада успешно изменены',
  client: 'Клиент',
  shop: 'Магазин',
  shops: 'Магазины',
  shopEditSuccess: 'Магазин успешно изменен',
  shopCreateSuccess: 'Магазин успешно создан',
  shopEditTitle: 'Редактирование магазина',
  shopcCreateTitle: 'Добавление магазина',
  status: 'Статус',
  transport: 'Транспорт',
  transportNumber: 'Госномер',
  transportWeightWithUnit: 'Грузоподъёмность, кг',
  transportEditSuccess: 'Транспорт успешно изменен',
  transportCreateSuccess: 'Транспорт успешно создан',
  transportEditTitle: 'Редактирование транспорта',
  transportCreateTitle: 'Добавление транспорта',
  user: 'Пользователь',
  users: 'Пользователи',
  userEdited: 'Пользователь изменён',
  userCreated: 'Пользователь создан',
  userEditTitle: 'Редактирование пользователя',
  userCreateTitle: 'Добавление пользователя',
  privacy: 'Политика конфиденциальности',
  locations: 'Локации',
  locationsList: 'Список локаций',
  locationCreateTitle: 'Добавление локации',
  locationEditTitle: 'Редактирование локации',
  locationWillBeSaved: 'Локация будет сохранена',
  locationCreated: 'Локация добавлена',
  locationEdited: 'Локация изменена',
  locationDeleted: 'Локация удалена',
  rates: 'Тарифы',
  ratesList: 'Список тарифов',
  rateDateTimeStart: 'Начало действия тарифа',
  rateDateTimeEnd: 'Конец действия тарифа',
  rateEditTitle: 'Редактирование тарифа',
  rateViewTitle: 'Просмотр тарифа',
  rateCreateTitle: 'Добавление тарифа',
  rateCopyTitle: 'Копирование тарифа',
  rateCreated: 'Тариф добавлен',
  rateEdited: 'Тариф изменен',
  rateDeleted: 'Тариф удален',
  createRate: 'Добавить тариф',
  finishTheRate: 'Завершить тариф',
  serviceZone: 'Сервисная зона',
  serviceZones: 'Зоны',
  serviceZonesList: 'Список зон',
  withoutServiceZone: 'Без сервисной зоны',
  serviceZoneEditTitle: 'Редактирование сервисной зоны',
  serviceZoneViewTitle: 'Просмотр сервисной зоны',
  serviceZoneCreateTitle: 'Добавление сервисной зоны',
  createServiceZone: 'Добавить сервисную зону',
  serviceZoneWillBeSaved: 'Сервисная зона будет сохранена',
  serviceZoneCreated: 'Сервисная зона добавлена',
  serviceZoneEdited: 'Сервисная зона изменена',
  serviceZoneDeleted: 'Сервисная зона удалена',
  coverages: 'Покрытия',
  coveragesList: 'Список покрытий',
  allCoverages: 'Все покрытия',
  coverageDeleted: 'Покрытие удалено',
  coverageAdded: 'Покрытие добавлено',
  coverageEdited: 'Покрытие изменено',
  withoutCoverages: 'Без покрытия',
  coverageEditTitle: 'Редактирование покрытия',
  coverageViewTitle: 'Просмотр покрытия',
  coverageCreateTitle: 'Добавить покрытие',
  schedules: 'Расписания',
  scheduleEditTitle: 'Редактирование расписания',
  scheduleViewTitle: 'Просмотр расписания',
  scheduleCreateTitle: 'Добавление расписания',
  scheduleDeleted: 'Расписание удалено',
  scheduleCopyTitle: 'Дублировать расписание',
  sessionsRoutes: 'Маршруты смены',
  timeslot: 'Временной слот',
  deliveryTime: 'плановое или фактическое время выполнения задания',
  role: 'Роль',
  phone: 'Телефон',
  contractor: 'Контрагент',
  contactPerson: 'Контактное лицо',
  contacts: 'Контакты',
  comment: 'Комментарий',
  createdDateTime: 'Дата создания',
  creator: 'Создано', // Создано: Сидоров В.
  resolution: 'Резолюция',
  courierSessionOnDate: 'Смена от {{content}}',
  courierSessionCreated: 'Новая смена настроена',
  courierSessionEdited: 'Смена изменена',
  courierSessionDeleted: 'Смена успешно удалена',
  courierSessionSetup: 'Настройка новой смены',
  courierSessionEditTitle: 'Редактирование смены',
  courierSessionCreateTitle: 'Добавить смену',

  // navigation
  goBackward: 'Вернуться назад',
  goIndexPage: 'на главную',
  goToCourierSession: 'Вернуться к смене',
  goToServiceZoneCreation: 'Перейти к созданию сервисной зоны',
  goToRateCreation: 'Перейти к созданию тарифа',
  goToCoverageCreation: 'Перейти к созданию покрытия',
  goToOrderList: 'К списку заказов',
  goToRouteList: 'К списку маршрутов',
  goToCourierList: 'К списку курьеров',
  goToList: 'Вернуться к списку',

  // actions
  authorize: 'Войти',
  save: 'Сохранить',
  calculate: 'Рассчитать',
  saveAll: 'Сохранить всё',
  calculateAll: 'Рассчитать всё',
  editCourier: 'Редактировать курьера',
  cancelTheRoute: 'Отменить маршрут',
  cancelTheRoutes: 'Отменить маршруты',
  add: 'Добавить',
  delete: 'Удалить',
  edit: 'Редактировать',
  createLocation: 'Добавить локацию',
  addTask: 'Добавить задание',
  sendForLoading: 'Отправить на погрузку',
  geocode: 'Геокодировать адрес',
  create: 'Создать',
  selectCourierForRoute: 'Выбрать курьера и машину',
  cleanup: 'Очистить',
  hideInactive: 'Скрыть неактивные',
  editWarehouseSettings: 'Изменить настройки склада',
  addDeliveryMethod: 'Добавить способ доставки',
  removeLastDeliveryMethod: 'Удалить последний способ доставки',
  beginTheSession: 'Начать смену',
  endTheSession: 'Завершить смену',
  refresh: 'Обновить',
  select: 'Выбрать',
  apply: 'Применить',
  showCoveragesByDeliveryMethod: 'Показать покрытия по способам доставки',
  applyToOtherDays: 'Применить к другим дням недели',
  hidePast: 'Скрыть прошедшие',

  // common
  yes: 'Да',
  no: 'Нет',
  or: 'или',
  cancel: 'Отмена',
  noData: 'Нет данных',
  nothingWasFound: 'Ничего не найдено',
  error: 'Ошибка',
  somethingIsWrong: 'Что-то пошло не так',
  areYouSure: 'Вы уверены?',
  sendToCourier: 'Отправить курьеру',
  lastSession: 'Последняя смена',
  saved: 'сохранено',
  oneMoreCoverage: 'Ещё одно покрытие',
  addressTitle: 'Адрес',
  extraTask: 'Дополнительное задание',
  deliveryDate: 'Дата доставки',
  timeSlotStart: 'Начало слота',
  timeSlotEnd: 'Конец слота',
  onAddress: 'по адресу',
  delivery: 'Доставка',
  addressesLength: 'Адресов:\u00A0{{content}}',
  tasksLength: 'заданий:\u00A0{{content}}',
  onTheList: 'Списком',
  onTheMap: 'На карте',
  loadingAtTime: 'В {{content}} погрузка',
  comeToWarehouseAtTime: 'В {{content}} возвращение на склад',
  sendToLoadTime: 'Время прибытия водителя на склад для погрузки',
  weightFullness: 'Загрузка по весу',
  volumeFullness: 'Загрузка по объему',
  filters: 'Фильтры',
  selected: 'Выбрано',
  personalData: 'Личные данные',
  authentication: 'Аутентификация',
  parameters: 'Параметры',
  mainWarehouseSettings: 'Основные настройки',
  problem: 'Проблемные',
  mondayShort: 'ПН',
  tuesdayShort: 'ВТ',
  wednesdayShort: 'СР',
  thursdayShort: 'ЧТ',
  fridayShort: 'ПТ',
  saturdayShort: 'СБ',
  sundayShort: 'ВС',
  time: 'Время',
  period: 'Период действия',
  number: 'Номер',
  atTime: 'в {{content}}',
  fromTime: 'c {{content}}',
  totalCount: 'Всего',
  sizes: 'Размеры',
  height: 'Высота',
  heightWithUnit: 'Высота, м.',
  heightTemplate: 'В: {{content}} м.',
  width: 'Ширина',
  widthWithUnit: 'Ширина, м.',
  widthTemplate: 'Ш: {{content}} м.',
  length: 'Длина',
  lengthWithUnit: 'Длина, м.',
  lengthTemplate: 'Д: {{content}} м.',
  type: 'Тип',
  automatic: 'автоматически',
  changedMyMind: 'Я передумал',
  priceUnitRub: 'руб.',
  averageSpeedUnitKmPerHour: 'км/час',
  timeUnitDayShort: 'д.',
  timeUnitHourShort: 'ч.',
  timeUnitMinuteShort: 'мин.',
  weightUnit: 'кг',
  lengthUnit: 'м',
  forDate: 'на {{content}}',
  byOrder: 'по заказу',
  search: 'Поиск',
  searchByTitle: 'Поиск по названию',
  searchByLocation: 'Поиск по локациям',
  searchByNumber: 'Поиск по номеру',
  searchByTitleOrNumber: 'Поиск по названию или госномеру',
  searchByFullNameEmailPhone: 'Поиск по ФИО, email или телефону',
  searchByTitleAddressPhone: 'Поиск по названию, адресу или телефону',

  // form fields
  date: 'Дата',
  from: 'Начало',
  to: 'Конец',
  disabled: 'неактивно',
  activity: 'Активность',
  title: 'Название',
  id: 'Идентификатор',
  externalId: 'Идентификатор во внешней системе',
  isActivated: 'Активирован',
  isActive: 'Активен',
  login: 'Логин',
  password: 'Пароль',
  passwordConfirmation: 'Подтверждение пароля',
  sessionPlannedDate: 'Дата начала смены',
  fullName: 'ФИО',
  coverageCreation: 'Создание покрытия',
  newCoverage: 'Новое покрытие',
  polygon: 'Полигон',
  autoDrawPolygon: 'Отрисовать полигон',
  autoDraw: 'Отрисовать',
  selectAll: 'Выбрать все',
  parentLocation: 'Родительская локация',
  positionOnTheMap: 'Положение на карте',
  destination: 'Место назначения',
  sender: 'Отправитель',
  recipient: 'Получатель',
  plannedDateTime: 'Ожидаемое время доставки',
  taskCancelationReason: 'Причина отмены задания',
  groupTasksByCoverage: 'Группировать по покрытиям',
  dayStart: 'Начало дня',
  dayEnd: 'Конец дня',
  interval: 'Интервал',
  setUpEqualIntervals: 'Настроить равные интервалы',
  orderLimit: 'Лимит заказов',
  price: 'Стоимость',
  averageSpeed: 'Средняя скорость',
  addNewRow: 'Добавить строку',
  shopShortName: 'Краткое наименование',
  shopLegalName: 'Юридическое наименование',
  shopCurrency: 'Валюта',
  volumeWithUnit: 'Объём, куб.м.',
  loadingTime: 'Время погрузки',
  surname: 'Фамилия',
  name: 'Имя',
  patronymic: 'Отчество',
  userStatus: 'Статус пользователя',
  latitude: 'Широта',
  longitude: 'Долгота',
  timezone: 'Таймзона',
  warehouseGroupId: 'Идентификатор группы складов',
  maxRouteTime: 'Максимальное время маршрута',
  maxCourierDowntime: 'Максимальное время простоя курьера',
  maxDelayTime: 'Максимальное время опоздания',
  maxLeadTime: 'Максимальное время опережения',
  unloadingTime: 'Время разгрузки',
  guardLimit: 'Защитный интервал',
  fullDayRoutes: 'Заполнение маршрутами на весь день',
  weight: 'Вес',
  volume: 'Объём',
  sum: 'Сумма',
  enterTitle: 'Введите название',
  enterId: 'Введите идентификатор',
  enterLogin: 'Введите логин',
  enterPassword: 'Введите пароль',
  enterPasswordConfirmation: 'Введите подтверждение пароля',
  enterTransport: 'Выберите транспорт',
  enterDate: 'Выберите дату',
  enterUserName: 'Введите имя пользователя',
  enterPolygon: 'Отметьте на карте область, занимаемую покрытием',
  enterLocationName: 'Введите локацию',
  enterLocation: 'Выберите локацию',
  enterWarehouse: 'Выберите склад',
  enterWarehouses: 'Выберите склады',
  enterLocationCenter: 'Подберите масштаб и установите маркер в центре локации',
  enterSearchQueryAddress: 'Введите адрес для поиска',
  enterTaskType: 'Выберите тип задания',
  enterDestination: 'Выберите место назначения',
  enterTime: 'Выберите время',
  enterCourier: 'Выберите курьера',
  enterHeight: 'Введите высоту',
  enterWidth: 'Введите ширину',
  enterLength: 'Введите длину',
  enterTaskCancelationReason: 'Выберите причину',
  enterServiceZone: 'Выберите сервисную зону',
  enterDeliveryMethod: 'Выберите способ доставки',
  enterCoverages: 'Выберите покрытия',
  enterDayStart: 'Укажите начало дня',
  enterDayEnd: 'Укажите конец дня',
  enterInterval: 'Укажите интервал',
  enterOrderLimit: 'Укажите лимит',
  enterPrice: 'Укажите стоимость',
  enterAverageSpeed: 'Введите скорость',
  enterShopShortName: 'Введите краткое наименование',
  enterShopLegalName: 'Введите юридическое наименование',
  enterTransportNumber: 'Введите госномер',
  enterTransportWeight: 'Введите грузоподъёмность',
  enterLoadingTime: 'Введите время',
  enterSurname: 'Введите фамилию',
  enterName: 'Введите имя',
  enterPatronymic: 'Введите отчество',
  enterEmail: 'Введите email',
  enterPhone: 'Введите телефон',
  enterRole: 'Выберите роль',
  enterAddress: 'Введите адрес',
  enterLatitude: 'Введите широту',
  enterLongitude: 'Введите долготу',
  enterPoint: 'Укажите точку на карте',
  enterTimezone: 'Выберите таймзону',
  enterWarehouseGroupId: 'Введите идентификатор группы складов',
  enterShops: 'Выберите магазины',
  enterMaxRouteTime: 'Введите время маршрута',
  enterMaxDelayTime: 'Введите время опоздания',
  enterMaxCourierDowntime: 'Введите время простоя курьера',
  enterMaxLeadTime: 'Введите время опережения',
  enterUnloadingTime: 'Введите время разгрузки',
  enterGuardLimit: 'Введите защитный интервал',
  enterWeight: 'Введите вес',
  enterVolume: 'Введите объём',

  // other
  possiblyLate: 'Возможно опоздание',
  latenessTitle: 'Опоздание',
  lateness_one: 'опоздание',
  lateness_few: 'опоздания',
  lateness_many: 'опозданий',
  lateness_other: 'опозданий',
  possiblyOutrunning: 'Возможно опережение',
  outrunningTitle: 'Опережение',
  outrunning_one: 'опережение',
  outrunning_few: 'опережения',
  outrunning_many: 'опережений',
  outrunning_other: 'опережений',
  task_one: 'задание',
  task_few: 'задания',
  task_many: 'заданий',
  task_other: 'заданий',
  coverage_one: 'покрытие',
  coverage_few: 'покрытия',
  coverage_many: 'покрытий',
  coverage_other: 'покрытий',
  address_one: 'адрес',
  address_few: 'адреса',
  address_many: 'адресов',
  address_other: 'адресов',
  bag_one: 'пакет',
  bag_few: 'пакета',
  bag_many: 'пакетов',
  bag_other: 'пакетов',

  // validators
  passwordRequiredValidator: 'Пожалуйста, укажите пароль',
  loginRequiredValidator: 'Пожалуйста, укажите логин',
  transportRequiredValidator: 'Выберите транспортное средство',
  requiredValidator: 'Поле обязательно для заполнения',
  moreThanNullNumberValidator: 'Значение должно быть больше нуля',
  positiveNumberValidator: 'Значение должно быть положительным',
  integerNumberValidator: 'Значение должно быть целым',
  compareWithPasswordValidator: 'Пароли должны совпадать',
  moreThanNullZoomValidator: 'Масштаб должен быть больше нуля',
  minIntervalValidator: 'Минимально допустимый интервал — 5 минут',
  maxIntervalValidator: 'Максимально допустимый интервал — 1 сутки',
  orderLimitValidator: 'Лимит заказов не может быть меньше 0',
  priceValidator: 'Стоимость не может быть меньше 0',
  surnameValidator: 'Пожалуйста, введите фамилию',
  nameValidator: 'Пожалуйста, введите имя',
  roleValidator: 'Пожалуйста, выберите роль',
  scheduleRequiredValidator: 'Необходимо добавить расписание',

  // confirm
  defaultConfirm: 'Вы уверены, что хотите {{content}}',
  deliveryMethodDeleteConfirm: 'Удалить способ доставки?',
  clientGroupDeleteConfirm: 'Удалить группу клиентов?',
  warehouseDeleteConfirm: 'Удалить склад?',
  shopDeleteConfirm: 'Удалить магазин?',
  transportDeleteConfirm: 'Удалить транспорт?',
  finishTheRateConfirm: 'Завершить тариф? Тариф прекратит действовать через 1 минуту.',
  leaveThePageConfirm: 'Вы уверены, что хотите покинуть страницу?',
  timeSlotDeleteConfirm: 'Удалить временной слот?',
  courierDeleteConfirm: 'Удалить курьера?',
  userDeleteConfirm: 'Удалить пользователя?',
  locationDeleteConfirm: 'Удалить локацию?',
  rateDeleteConfirm: 'Удалить тариф?',
  serviceZoneDeleteConfirm: 'Удалить сервисную зону?',
  coverageDeleteConfirm: 'Удалить покрытие?',
  coverageDelete: 'Удалить покрытие',
  coverageDeleteFromListConfirm: 'Удалить покрытие из списка?',
  endCourierSessionConfirm:
    'Вы действительно хотите завершить смену? Все незавершенные маршруты будут отменены.',

  // notifications & phrases
  addressIsNotDefined: 'Адрес не определен',
  cannotCallTheClient: 'Курьер не смог дозвониться до клиента',
  courierIsNotSelected: 'Курьер и машина',
  sessionWillBeCreateAutomatically: 'Смена будет создана автоматически',
  transportIsNotCompareToWarehouse: 'Склад не совпадает с доступным автомобилю',
  courierIsNotCompareToWarehouse: 'Склад не совпадает с доступным курьеру',
  transportIsNotCompareToDeliveryMethod: 'Способ доставки не совпадает с доступным автомобилю',
  thereIsActiveRoutesInSession: 'В смене есть незавершенные маршруты',
  locationHasNoRates: 'У локации нет действующих тарифов',
  letsCreateRoute: 'Перетащите сюда задания, чтобы составить маршрут.',
  thereAreNoZones: 'Здесь нет активных зон обслуживания',
  thereAreSomeProblems: 'Обнаружены проблемы',
  noSectionFound: 'Раздел отсутствует',
  accessDenied: 'Доступ запрещён',
  noTasks: 'Нет заданий',
  notDefined: 'Не определено',
  thereAreTasksWithNotAvailableDeliveryMethodsForTransport:
    'Автомобиль не поедет: есть задания с недоступным способом доставки',
  thereAreTasksWithNotAvailableWarehouseForTransport:
    'Автомобиль не поедет: есть задания с недоступным складом',
  thereAreTasksWithNotAvailableWarehouseForCourier:
    'Курьер не поедет: есть задания с недоступным складом',
  letsCreateCourierSession:
    'Нажмите ”Добавить смену”, чтобы запланировать смену для курьера на этот день',
  warehouseCreatedWithNoCoords:
    'Склад создан с ошибкой геокодирования. Уточните адрес склада для активации',
  maxDelayTimeMeaning:
    'Если установлено значение 0, то опоздание запрещено. Если поле не заполнено, то ограничение отсутствует',
  maxLeadTimeMeaning:
    'Если установлено значение 0, то опережение запрещено. Если поле не заполнено, то ограничение отсутствует',
  fullDayRoutesMeaning: 'Распределить заказы на весь день',
  searchByFullName: 'Поиск по ФИО',
  defaultSort: 'Сортировка по умолчанию',
  ascendingAmountSort: 'Сумма по возрастанию',
  descendingAmountSort: 'Сумма по убыванию',
  ascendingWeightSort: 'Вес по возрастанию',
  descendingWeightSort: 'Вес по убыванию',
  ascendingVolumeSort: 'Объем по возрастанию',
  descendingVolumeSort: 'Объем по убыванию',
  couriersSuccessfullySend: 'Курьеры отправлены на погрузку',
  courierHasProcessingRoute: 'У курьера есть исполняемый маршрут',
  courierHasProcessingSession: 'У курьера есть исполняемая смена',
  readyToSendCouriers: 'Курьеры, готовые к отправке',
  noCouriersToSend: 'Нет ни одного курьера, готового к отправке',
  addExtraTask: 'Создать дополнительное задание',
  taskCreationReason: 'Опишите причину',
  extraTaskPreRequest: 'Курьер встретился с клиентом?',
  extraTaskReasons: 'Дополнительное задание создается если нужно:',
  extraTaskReason1: 'Оформить возврат',
  extraTaskReason2: 'Довезти товары',
  extraTaskReason3: 'Заменить товары',
  extraTaskWarning: 'Такое задание не будет отражено в Учетной системе.',
  extraTaskAction: 'Если причина другая, обратитесь в Техподдержку',
  deleteTasksFromRoute: 'Удалить из маршрута', // временно используется removeTaskFromRoute
  fastDelivery: 'Быстрая доставка',
  inUse: 'Используется',
  saleNumbers: 'Накладные',
  accountSystems: 'Учетные системы',
  fullScreenMode: 'Полноэкранный режим',
  distance: 'Плановая длина маршрута',
  distanceShort: 'Длина маршрута',
  distanceUnit: 'км',
  getAdjacentCoverages: 'Показать соседние покрытия',
  menu: 'Меню',
  // TODO нужен перевод на другие языки
  countries: 'Страны',
  country: 'Страна',
  cities: 'Города',
  countryEditSuccess: 'Страна успешно изменена',
  countryCreateSuccess: 'Страна успешно создана',
  countryEditTitle: 'Редактирование страны',
  countryCreateTitle: 'Добавление страны',
  cityEditSuccess: 'Город успешно изменен',
  cityCreateSuccess: 'Город успешно создан',
  cityEditTitle: 'Редактирование города',
  cityCreateTitle: 'Добавление города',
  warehouse_one: 'склад',
  warehouse_few: 'склада',
  warehouse_many: 'складов',
  warehouse_other: 'складов',
  courier_one: 'курьер',
  courier_few: 'курьера',
  courier_many: 'курьеров',
  courier_other: 'курьеров',
  clusters: 'Кластеры',
  listOfClusters: 'Список кластеров',
  createClusters: 'Создать кластер',
  information: 'Информация',
  setting: 'Настройка',
  editSuccess: 'Изменения сохранены',
  clusterCreateSuccess: 'Кластер успешно создан',
  clusterEditTitle: 'Редактирование кластера',
  clusterCreateTitle: 'Создание кластера',
  clusterEditTitleSettings: 'Редактирование настроек',
  next: 'Далее',
  city: 'Город',
  locality: 'Населенный пункт',
  enterCountry: 'Введите страну',
  enterLocality: 'Введите населенный пункт',
  enterClusterName: 'Введите название кластера',
  step: 'Шаг',
  startDateAndTime: 'Дата и время начала работы',
  routingStartPlan: 'План начала маршрутизации',
  startingPoint: 'Точка отсчёта',
  startingPointPlaceholder: 'От какой точки начать расчёт',
  shearTime: 'Время сдвига',
  shearTimePlaceholder: 'Введите время сдвига',
  fieldPlaceholder: 'Заполнитель поля',
  newDeliveryMethod: 'Новый способ доставки',
  order_assembly_end: 'Время окончания сборки',
  order_slot_start: 'Время начала слота',
  startingPointMeans:
    'Это точка, с которой начинается отсчет времени, когда заказ должен попасть в автоматическую систему распределения заказов.',
  shearTimeMeans:
    'Время сдвига — это время, которое будет прибавлено к точке отсчета или отнято от точки отсчета. В полученный результат система начнет составлять маршрут для заказа.',
  addCouriers: 'Добавьте курьеров',
  noClusters: 'Пока не создано ни одного кластера',
  byTitle: 'По названию',
  byCourier: 'По курьерам',
  clusterCreated: 'Кластер успешно создан',
  courierAverageSpeed: 'Средняя скорость курьера',
  orderActualStrategy: 'Cтратегия расчета',
  orderActualStrategyMeans:
    'Стратегия, которая определяет от какого момента считать время начала маршрутизации заказа',
  fast_mine_mode: 'Автоматический',
  courier_council_mode: 'Курьерский',
  cluster_mode: 'Режим кластера',
  enterClientGroup: 'Выберите группу клиентов',
  searchByNumberContractor: 'Поиск по номеру заказа и КА',
  // надо на другие языки
  planActual: 'План / факт',
  loadingAtWarehouse: 'Начало маршрута',
  completingRoute: 'Завершение маршрута',
  calculateByYandex: 'Рассчитать яндексом',
  calculateByYandexAll: 'Рассчитать всё яндексом',
  planUploadTime: 'Плановая дата и время отгрузки товаров со склада',
  forTheAccountant: '(только для бухгалтерии РФ)',
  enterDateTime: 'Выберите дату и время',
  noShipmentFromTheWarehouse: 'Отгрузки со склада не будет',
  latitudeValidator: 'Значение широты должно быть в интервале {-90:+90}',
  longitudeValidator: 'Значение долготы должно быть в интервале {-180:+180}',
  reasonDelay: 'Причина опоздания',
  reasonsDelay: 'Причины опоздания',
  requiredComment: 'Обязательный комментарий',
  titleOfReasonDelay: 'Название причины опоздания',
  deleteReasonDelay: 'Удалить причину опоздания',
  reasonDelayEditSuccess: 'Причина опоздания успешно изменена',
  reasonDelayCreateSuccess: 'Причина опоздания успешно создана',
  reasonDelayEditTitle: 'Редактирование причины опоздания',
  reasonDelayCreateTitle: 'Добавление причины опоздания',
  comment_one: 'комментарий',
  comment_few: 'комментария',
  comment_many: 'комментариев',
  comment_other: 'комментариев',
  routeStartTime: 'Время начала маршрута',
  routeStart: 'Начало маршрута',
  intercom: 'Домофон',
  intercomNotWorking: 'не работает, позвонить по приезде',
  intercomCode: 'код',
  barrier: 'Шлагбаум',
  barrierExist: 'есть',
  barrierNotExist: 'нет',
  barrierCall: 'есть, звоните, открою по приезде',
  paymentMethods: 'Способы оплаты',
  paymentMethod: 'Способ оплаты',
  paymentMethodEditSuccess: 'Способ оплаты успешно изменен',
  paymentMethodCreateSuccess: 'Способ оплатв успешно создан',
  paymentMethodEditTitle: 'Редактирование способа оплаты',
  paymentMethodCreateTitle: 'Добавление способа оплаты',
  paymentMethodCode: 'Код',
  paymentMethodWebName: 'Способ оплаты',
  paymentMethodMobName: 'Способ оплаты в МПК',
  paymentMethodNoPermissions:
    'Заказ можно взять в маршрут, если заказ в статусе оплаты "Не оплачен"',
  paymentStatus: 'Статус оплаты',
  paymentPaid: 'Оплачен',
  paymentNotPaid: 'Не оплачен',
  infoByAddress: 'Информация по адресу',
  noTariffByAddress: 'Адрес не включен в зону обслуживания',
  tariffNotSet: 'Расписание не настроено',
  setUpTariffs: 'Настроить',
  showTariff: 'Смотреть расписание',
  notificationsFromCouriers: 'Уведомления от курьеров',
  enableNotificationsFromCouriers: 'Включить уведомления от курьеров',
  profileDeactivation: 'Деактивация профиля',
  deactivationDate: 'Дата деактивации',
  selfEmployedCourier: 'Самозанятый курьер',
  priorityOfReasonDelay: 'Приоритет',
  enterPriority: 'Введите значение (от 1 до 9999)',
  priorityNumberValidator: 'Поле содержит неверный тип данных',
  baseTime: 'Базовое время',
  unloadingTimeCalculation: 'Расчет времени разгрузки',
  orderWeightForExtraDelay: 'Предельный вес для повышения времени',
  enterOrderWeightForExtraDelay: 'Введите предельный вес',
  orderUnloadingExtraDelay: 'Дополнительное время',
  enterOrderUnloadingExtraDelay: 'Введите дополнительное время',
  more: 'Ещё',
  now: 'Сейчас',
  selectCurrency: 'Выберите валюту',
  areYouSureYouWantToRemoveTheCoverage: 'Вы уверены, что хотите удалить покрытие?',
  lines: 'Направления',
  linesList: 'Список направлений',
  coverageList: 'Покрытия',
  lineName: 'Название',
  enterLineName: 'Введите название',
  lineCourier: 'Водитель',
  linesEditTitle: 'Редактирование направления',
  linesCreateTitle: 'Добавление нового направления',
  linesEditSuccess: 'Направление успешно изменено',
  linesCreateSuccess: 'Направление успешно создано',
  searchByCourierOrName: 'Поиск по названию, водителю',
  toTheSelectionOfParameters: 'К выбору параметров',
  toTheRouteGenerator: 'К генератору маршрутов',
  confirmDeleteRoutes:
    'Вы уверены, что хотите создать новый маршрут? Текущий маршрут будет удалён.',
  selectDirections: 'Выберите направления',
  addToRoutes: 'Добавить в маршруты',
  noSuitableDestinationsToAddToTheRoute: 'Нет подходящих направлений для добавления в маршрут',
  goToDirections: 'Перейти в направления',
  addDirection: 'Добавить направление',
};
