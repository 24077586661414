import { IFilter } from 'modules/arm2/models/types';

export const getFilterFromLocalStorage = (id: string): IFilter => {
  const filterString = localStorage.getItem(id);
  return JSON.parse(filterString);
};

export const setFilterToLocalStorage = (id: string, filter: IFilter): void => {
  const filterString = JSON.stringify(filter);
  localStorage.setItem(id, filterString);
};
