import React from 'react';

import { Route, Switch } from 'react-router-dom';

import RouteGeneratorPage from 'src/modules/routeGenerator/components/RouteGeneratorPage';

const RouteGenerator = () => {
  const baseUrl = '/arm2/route-generator';

  const renderCreateForm = (): React.ReactNode => <RouteGeneratorPage />;

  return (
    <Switch>
      <Route exact path={`${baseUrl}`} render={renderCreateForm} />
    </Switch>
  );
};

export default RouteGenerator;
