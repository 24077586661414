import './style.less';

import React, { useState } from 'react';

import { Button, Checkbox } from 'antd';
import { observer } from 'mobx-react-lite';

import classSet from 'helpers/classSet';
import DateFilter from 'modules/arm2/components/DateFilter';
import DeliveryMethodFilter from 'modules/arm2/components/DeliveryMethodFilter';
import WarehouseFilter from 'modules/arm2/components/WarehouseFilter';
import { FilterIcon } from 'modules/common/components/Icons';
import { translate } from 'modules/localization';
import RefreshButton from 'modules/routeGenerator/components/common/RefreshButton';
import { useStore } from 'services/store';

import SearchFilter from './SearchFilter';

const GeneratorFilter = () => {
  const { routeGenerator } = useStore();

  const [viewFilter, setViewFilter] = useState(false);

  return (
    <>
      <div className="rg-filter__button">
        <RefreshButton />
        <Button
          type="link"
          icon={<FilterIcon />}
          onClick={() => setViewFilter(!viewFilter)}
          className={classSet({
            'rg-filter-button ': true,
            'rg-filter-button--visibleFilter': viewFilter,
            'rg-filter-button--activeFilter': !!routeGenerator.searchFilterValue,
          })}
        />
      </div>
      {viewFilter ? (
        <div className="rg-filter__content">
          <div className="rg-filter__content-part">
            <SearchFilter />

            <Checkbox
              onChange={(e) => routeGenerator.setIsGroupedByCoverage(e.target.checked)}
              checked={routeGenerator.isGroupedByCoverage}
            >
              {translate('groupTasksByCoverage')}
            </Checkbox>
          </div>
          <div className="rg-filter__content-part">
            <DateFilter
              className="rg-filter__date"
              value={routeGenerator.filterTasks.deliveryDate}
              defaultValue={routeGenerator.filterTasks.deliveryDate}
              disabled
            />

            <DeliveryMethodFilter
              className="rg-filter__select rg-filter__delivery-methods"
              value={routeGenerator.filterTasks.deliveryMethod}
              maxTagCount={2}
              disabled
            />

            <WarehouseFilter
              className="rg-filter__select"
              value={
                Array.isArray(routeGenerator.filterTasks.warehouses)
                  ? routeGenerator.filterTasks.warehouses
                  : [routeGenerator.filterTasks.warehouses]
              }
              defaultValue={routeGenerator.routeSettings.warehouses}
              disabled
            />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default observer(GeneratorFilter);
