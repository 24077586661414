import React from 'react';

import { observer } from 'mobx-react-lite';

import ListMobX from 'modules/directory/containers/List/List';
import { useStore } from 'services/store';

import { getColumns } from './helpers';

interface IProps {
  baseUrl: string;
}

const ReasonsDelayList = ({ baseUrl }: IProps) => {
  const { reasonsDelay } = useStore();

  return (
    <ListMobX
      baseUrl={baseUrl}
      actionListGet={reasonsDelay.getList}
      list={reasonsDelay.list}
      filter={reasonsDelay.filter}
      getColumns={getColumns}
      isLoading={reasonsDelay.isLoadingList}
      actionDelete={reasonsDelay.delete}
      actionSearch={reasonsDelay.search}
    />
  );
};

export default observer(ReasonsDelayList);
