import './style.less';

import React from 'react';

import { observer } from 'mobx-react-lite';

import ListMobX from 'modules/directory/containers/List/List';
import { getColumns } from 'modules/warehouses/components/List/helpers';
import { useStore } from 'services/store';

interface IProps {
  baseUrl: string;
}

const WarehousesList = ({ baseUrl }: IProps) => {
  const { warehouses } = useStore();

  return (
    <ListMobX
      baseUrl={baseUrl}
      actionListGet={warehouses.getList}
      actionSearch={warehouses.search}
      list={warehouses.list}
      filter={warehouses.filter}
      getColumns={getColumns}
      isLoading={warehouses.isLoadingList}
      actionDelete={warehouses.delete}
    />
  );
};

export default observer(WarehousesList);
