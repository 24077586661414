import React, { ReactElement } from 'react';

import { Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { IFilter } from 'modules/arm2/models/types';
import { IPermission } from 'modules/core/models/types';
import { ICoverage } from 'modules/coverages/models/types';
import { IDeliveryMethods } from 'modules/delivery-methods/models/types';
import Actions from 'modules/directory/components/ActionButtons';
import { ILine } from 'modules/lines/models/types';
import { translate } from 'modules/localization';
import { getTransportName } from 'modules/transports/models/helpers';
import { ITransport } from 'modules/transports/models/types';
import { getFullName } from 'modules/user/models/helpers';
import { IProfile } from 'modules/user/models/types';

export const getColumns = (
  baseUrl: string,
  permissions: IPermission,
  actionDelete: (guid: string) => void,
  sortOptions: IFilter,
): ColumnsType<ILine> => {
  return [
    {
      dataIndex: 'name',
      title: translate('title'),
      key: 'name',
      width: '20%',
      sorter: true,
      sortOrder: sortOptions.columnKey === 'name' ? sortOptions.order : null,
    },
    {
      dataIndex: 'courier',
      title: translate('lineCourier'),
      key: 'courier_full_name',
      width: '16%',
      render: (courier: IProfile): string => getFullName(courier),
      sorter: true,
      sortOrder: sortOptions.columnKey === 'courier_full_name' ? sortOptions.order : null,
    },
    {
      dataIndex: 'transport',
      title: translate('transport'),
      key: 'transport',
      width: '14%',
      render: (transport: ITransport): string => getTransportName(transport),
    },
    {
      dataIndex: 'delivery_method',
      title: translate('deliveryMethod'),
      key: 'delivery_method',
      width: '14%',
      render: (dm: IDeliveryMethods): string => dm.name,
    },
    {
      dataIndex: 'coverage_list',
      title: translate('coverageList'),
      key: 'coverage_list',
      width: '26%',
      render: (list: ICoverage[]): ReactElement => {
        const coveragesList = list?.map((item) => item.name).join(', ') || '';
        return (
          <Typography.Paragraph
            ellipsis={{
              rows: 1,
              expandable: false,
              expanded: false,
              suffix: null,
              tooltip: coveragesList,
            }}
          >
            {coveragesList}
          </Typography.Paragraph>
        );
      },
    },
    {
      dataIndex: 'guid',
      key: 'action',
      render: (_, item): React.ReactNode => (
        <Actions
          item={item}
          baseUrl={baseUrl}
          actionDelete={actionDelete}
          permissions={{
            edit: permissions['lines.update'],
            delete: permissions['lines.delete'],
          }}
          message={{
            deleteCancel: translate('cancel'),
            deleteTitle: translate('areYouSure'),
          }}
        />
      ),
      width: '10%',
    },
  ];
};
