import React from 'react';

import dayjs from 'dayjs';
import localForage from 'localforage';
import uniq from 'lodash.uniq';
import { makeAutoObservable, runInAction, toJS } from 'mobx';
import { makePersistable } from 'mobx-persist-store';

import { RestException } from 'api/RestException';
import { isPastDay } from 'helpers/date';
import { dateFormat } from 'helpers/string';
import { generateUid } from 'helpers/uid';
import CouriersApi from 'modules/couriers/api/CouriersApi';
import { getSessionByDate } from 'modules/routeGenerator/components/right/RouteCard/helpers';
import { initialRouteSettings } from 'modules/routeGenerator/models/initial';
import {
  GeneratorPageName,
  ILineChecked,
  IRouteDraft,
  IRouteSettings,
} from 'modules/routeGenerator/models/types';
import {
  checkTaskForCompatibility,
  getRouteDraftTaskList,
  highlight,
  sortRoutesByCourierName,
  tasksSortByTimeSlots,
} from 'modules/routeGenerator/models/utils';
import { RouteGeneratorApiStore } from 'modules/routeGenerator/stores/RouteGeneratorApiStore';
import { RouteGeneratorDnDStore } from 'modules/routeGenerator/stores/RouteGeneratorDnDStore';
import {
  filterTaskList,
  filterTasksByRecipient,
  getCoveragesFromTaskList,
  groupTasksByCoverages,
  groupTasksWithRelatives,
  ungroupTasksWithRelatives,
} from 'modules/tasks/helpers/lists';
import { initialGeneratorFilter as initialFilterTasks } from 'modules/tasks/models/initial';
import { ITask, ITasksFilter } from 'modules/tasks/models/types';
import { getTaskRelativeGuids } from 'modules/tasks/models/utils';
import TransportApi from 'modules/transports/api/TransportApi';
import { getNameInitials } from 'modules/user/models/helpers';
import { IWarehouses } from 'modules/warehouses/models/types';
export class RouteGeneratorStore {
  apiStore: RouteGeneratorApiStore;
  dndStore: RouteGeneratorDnDStore;

  currency: string = null;
  tasksListFromApi: ITask[] = [];

  routeSettings: IRouteSettings = initialRouteSettings;
  filterTasks: ITasksFilter = initialFilterTasks;
  routeDraftList: IRouteDraft[] = [];

  activeRequestTasksList: AbortController[] | null = null;
  selectedTaskGuids: React.Key[] = [];
  openCardRouteDraftId: string = null;
  isGroupedByCoverage = true;
  scrollTop: number = null;

  searchFilterValue: string = null;
  allSearchOptions: { value: string; name: string }[] = [];
  currentSearchOptions: { value: string; name: string }[] = [];
  highlightedTasks: string[] = [];

  activeWarehouse: IWarehouses;

  lastInteractRouteID: string;

  isLoadingCalculate = false;
  isLoadingSave = false;
  isLoadingCalculateYandex = false;

  activePage: GeneratorPageName = GeneratorPageName.FORM;

  linesList: ILineChecked[] = [];
  isLoadingLinesList: boolean = false;

  get linesListFilter(): ILineChecked[] {
    if (this.taskListSource.length === 0) {
      return [];
    }
    const groupedCoverageGuids: string[] = Object.keys(groupTasksByCoverages(this.taskListSource));
    return this.linesList.filter(
      (line) =>
        line.coverage_list.filter((coverage) => groupedCoverageGuids.includes(coverage.guid))
          .length > 0 && this.tasksIncludesInCoverageGuids(line).length > 0,
    );
  }

  get getLinesListIsCheckedLength(): number {
    return this.linesListFilter.filter((line) => line.checked === true).length;
  }

  get getCoveragesGuidLinesRepeat(): string[] {
    const allCoverages = this.linesListFilter
      .map((line) => line.coverage_list.map((coverage) => coverage.guid))
      .flat();
    const uniqSet = new Set<string>();
    for (let i = 0; i < allCoverages.length; i++) {
      for (let j = 0; j < i; j++) {
        if (allCoverages[j] === allCoverages[i]) {
          uniqSet.add(allCoverages[i]);
        }
      }
    }

    return Array.from(uniqSet);
  }

  get isLoadingTasksList(): boolean {
    return this.activeRequestTasksList !== null;
  }

  get getIsLoadingCalculate(): boolean {
    return this.isLoadingCalculate;
  }

  get getIsLoadingSave(): boolean {
    return this.isLoadingSave;
  }

  get getIsLoadingCalculateYandex(): boolean {
    return this.isLoadingCalculateYandex;
  }

  get movedTasksToRoutes(): string[] {
    return ungroupTasksWithRelatives(
      this.routeDraftList.reduce((acc: ITask[], rd) => [...acc, ...rd.tasksList], []),
    ).map((t) => t.guid);
  }

  get taskListSource(): ITask[] {
    const filteredTasks = filterTaskList(this.tasksListFromApi, this.movedTasksToRoutes);
    return groupTasksWithRelatives(filteredTasks);
  }

  constructor() {
    this.apiStore = new RouteGeneratorApiStore(this);
    this.dndStore = new RouteGeneratorDnDStore(this);

    makeAutoObservable(this);

    makePersistable(this, {
      name: 'RouteGeneratorStore',
      properties: ['routeSettings', 'filterTasks', 'routeDraftList'],
      storage: localForage,
    }).finally(() => {
      if (this.routeDraftList.length === 0 || isPastDay(String(this.routeSettings.deliveryDate))) {
        runInAction(() => {
          this.routeSettings = initialRouteSettings;
          this.filterTasks = initialFilterTasks;
          this.routeDraftList = [];
        });
      } else {
        runInAction(() => {
          this.routeSettings = {
            ...this.routeSettings,
            deliveryDate: dayjs(this.routeSettings.deliveryDate),
          };
          this.activePage = GeneratorPageName.GENERATOR;
        });
      }
    });
  }

  getRouteDraftIndex = (routeId: string): number => {
    return this.routeDraftList.findIndex((item) => item.uid === routeId);
  };

  getRouteDraft = (routeId: string): IRouteDraft => {
    return this.routeDraftList.find((item) => item.uid === routeId);
  };

  setupRouteSettings = (settings: IRouteSettings): void => {
    this.routeSettings = settings;
    this.filterTasks = {
      ...this.filterTasks,
      ...settings,
      deliveryDate: settings.deliveryDate.format(dateFormat.search),
    };
  };

  setActiveWarehouse = (warehouse: IWarehouses): void => {
    this.activeWarehouse = warehouse;
    this.setCurrency(warehouse.shops[0].currency);
  };

  setCurrency = (currency: string): void => {
    this.currency = currency;
  };

  setSelectedTaskGuids = (guids: React.Key[]): void => {
    const guidsSet = new Set(guids);
    this.selectedTaskGuids = Array.from(guidsSet);
  };

  selectTaskList = (guidsToSelect: string[]): void => {
    const newSelectedTaskGuids = [...this.selectedTaskGuids.slice(), ...guidsToSelect];
    this.setSelectedTaskGuids(newSelectedTaskGuids);
  };

  deselectTaskList = (guidsToSelect: string[]): void => {
    let newSelectedTaskGuids = this.selectedTaskGuids.slice();
    guidsToSelect.map((guid) => {
      newSelectedTaskGuids = newSelectedTaskGuids.filter((g) => g !== guid);
    });
    this.setSelectedTaskGuids(newSelectedTaskGuids);
  };

  setRouteDraftList = (
    taskList: ITask[],
    droppableId: string,
    setLastInteract: boolean = false,
  ): void => {
    const routeDraftIndex = this.getRouteDraftIndex(droppableId);
    const { tasks, error } = checkTaskForCompatibility(
      taskList,
      routeDraftIndex !== -1 ? this.routeDraftList[routeDraftIndex] : {},
    );

    if (droppableId === 'new') {
      const uid = generateUid();
      const routeDraft: IRouteDraft = {
        tasksList: tasksSortByTimeSlots(tasks),
        isLoadingCard: false,
        isCalculated: false,
        error,
        coverages: getCoveragesFromTaskList(tasks),
        uid,
        isPlanUploadTimeActive: Boolean(this.activeWarehouse?.config?.route_build),
      };
      this.routeDraftList.unshift(routeDraft);
      setLastInteract && this.setLastInteractRouteID(uid);
    } else {
      const uid = this.routeDraftList[routeDraftIndex].uid;
      this.routeDraftList[routeDraftIndex] = {
        ...this.routeDraftList[routeDraftIndex],
        tasksList: tasksSortByTimeSlots(tasks),
        isLoadingCard: false,
        isCalculated: false,
        error,
        coverages: getCoveragesFromTaskList(tasks),
      };
      setLastInteract && this.setLastInteractRouteID(uid);
    }
  };

  moveSourceTasksToRoute = (
    destination: string,
    selectedTaskGuids: React.Key[],
    setLastInteract: boolean = true,
  ): void => {
    const newTasksListRouteDraft = getRouteDraftTaskList({
      tasksListSource: toJS(this.taskListSource).slice(),
      routeDraftList: toJS(this.routeDraftList).slice(),
      selectedTaskGuids: toJS(selectedTaskGuids).slice(),
      destination,
    });
    this.setRouteDraftList(newTasksListRouteDraft, destination, setLastInteract);
  };

  moveSelectedTasksToRoute = (): void => {
    const destination = this.openCardRouteDraftId !== null ? this.openCardRouteDraftId : 'new';
    this.moveSourceTasksToRoute(destination, this.selectedTaskGuids);
    this.selectedTaskGuids = [];
  };

  setOpenCardRouteDraftId = (routeId: string): void => {
    this.openCardRouteDraftId = routeId;
  };

  setScrollTop = (scrollTop: number): void => {
    this.scrollTop = scrollTop;
  };

  setTasksListSource = (res: ITask[]): void => {
    this.tasksListFromApi = res;
    this.calculateSearchOptions();
  };

  removeRoute = (routeId: string): void => {
    this.routeDraftList = this.routeDraftList.filter((item) => item.uid !== routeId);
  };

  hideSavedTasks = (routeId: string): void => {
    const taskInRouteDraft = this.routeDraftList.find((item) => item.uid === routeId).tasksList;
    const taskToHide = ungroupTasksWithRelatives(taskInRouteDraft).map((i) => i.guid);
    this.tasksListFromApi = this.tasksListFromApi.filter(
      (item) => taskToHide.indexOf(item.guid) === -1,
    );
  };

  removeTaskListFromRouteDraft = (taskList: ITask[], routeId: string): void => {
    const guidsToRemove = taskList.reduce(
      (acc, task) => [...acc, ...getTaskRelativeGuids(task)],
      [],
    );

    const tasks = groupTasksWithRelatives(
      ungroupTasksWithRelatives(this.getRouteDraft(routeId).tasksList).filter(
        (item) => guidsToRemove.indexOf(item.guid) === -1,
      ),
    );

    this.setRouteDraftList(tasks, routeId);
  };

  setCourierDataRouteDraft = (
    courierData: Omit<
      IRouteDraft,
      'coverages' | 'isCalculated' | 'isLoadingCard' | 'tasksList' | 'uid'
    >,
    routeId: string,
  ): void => {
    const taskForCompatibility = checkTaskForCompatibility(
      this.getRouteDraft(routeId).tasksList,
      courierData,
    );
    this.routeDraftList[this.getRouteDraftIndex(routeId)] = {
      ...this.getRouteDraft(routeId),
      ...courierData,
      tasksList: tasksSortByTimeSlots(taskForCompatibility.tasks),
      error: taskForCompatibility.error,
      isCalculated: false,
    };

    this.sortRoutesByCourierName();
  };

  setIsGroupedByCoverage = (value: boolean): void => {
    this.isGroupedByCoverage = value;
  };

  sortRoutesByCourierName() {
    this.routeDraftList = sortRoutesByCourierName(this.routeDraftList);
  }

  refreshRouteDraftList = (): void => {
    if (this.routeDraftList.length > 0) {
      this.routeDraftList = this.routeDraftList.map((rd) => ({
        ...rd,
        tasksList: this.refreshTaskList(rd.tasksList),
        isCalculated: false,
        isLoadingCard: false,
        error: null,
        start: null,
        finish: null,
        warehouse: null,
      }));
    }
  };

  refreshTaskList = (taskList: ITask[]): ITask[] => {
    if (taskList.length === 0) {
      return taskList;
    }

    const ungroupedTaskList = ungroupTasksWithRelatives(taskList);
    const newUngroupedTaskList = ungroupedTaskList.reduce((list, task) => {
      const actualTask = this.tasksListFromApi.find(({ guid }) => guid === task.guid);
      if (actualTask) {
        list.push(actualTask);
      }
      return list;
    }, []);

    return groupTasksWithRelatives(newUngroupedTaskList);
  };

  setHighlightedTasks = (list: string[]) => {
    this.highlightedTasks = list;
  };

  setSearchFilterValue = (value: string) => {
    this.searchFilterValue = value;

    if (!value) {
      this.setHighlightedTasks([]);
    } else if (value.includes('recipient')) {
      this.setHighlightedTasks(
        filterTasksByRecipient(this.tasksListFromApi, value.split('_')[1]).map((t) => t.guid),
      );
    } else {
      this.setHighlightedTasks([value]);
    }
  };

  calculateSearchOptions = (): void => {
    const recipientOptions = uniq(
      this.tasksListFromApi.map((item) => item.meta.recipient_company_short_name),
    )
      .filter((item) => item.length > 0)
      .map((name) => ({ value: `recipient_${name}`, name: name }));
    const taskOptions = this.tasksListFromApi.map((t) => ({ value: t.guid, name: `#${t.number}` }));
    this.allSearchOptions = [...recipientOptions, ...taskOptions];

    this.setDefaultSearchOptions();
  };

  setDefaultSearchOptions = (): void => {
    this.currentSearchOptions = this.allSearchOptions.slice(0, 99);
  };

  search = (query: string): void => {
    if (!query) {
      this.setDefaultSearchOptions();
    } else {
      this.currentSearchOptions = this.allSearchOptions.filter(
        (o) => o.name.toLowerCase().indexOf(query.toLowerCase()) >= 0,
      );
    }
  };

  setLastInteractRouteID = (id: string): void => {
    this.lastInteractRouteID = id;
  };

  switchIsLoadingCalculate = (isLoading: boolean) => {
    this.isLoadingCalculate = isLoading;
  };

  switchIsLoadingSave = (isLoading: boolean) => {
    this.isLoadingSave = isLoading;
  };

  switchIsLoadingCalculateYandex = (isLoading: boolean) => {
    this.isLoadingSave = isLoading;
  };

  setActivePage = (pageName: GeneratorPageName) => {
    this.activePage = pageName;
  };

  removeAllRoutes = () => {
    this.routeDraftList = [];
  };

  setSelectAllLines = (isChecked: boolean) => {
    this.linesList = this.linesList.map((line) => {
      return { ...line, checked: isChecked };
    });
  };

  changeSelectLine = (isChecked: boolean, guid: string): void => {
    this.linesList = this.linesList.map((line) => {
      if (line.guid === guid) {
        return { ...line, checked: isChecked };
      }
      return line;
    });
  };

  tasksIncludesInCoverageGuids = (line: ILineChecked): React.Key[] => {
    const tasksGrouped = groupTasksByCoverages(
      highlight(this.taskListSource, this.highlightedTasks),
    );
    let selectedTasks: React.Key[] = [];
    line.coverage_list.map((coverage) => {
      if (tasksGrouped && tasksGrouped[coverage.guid]) {
        selectedTasks = [
          ...selectedTasks,
          ...tasksGrouped[coverage.guid].tasks
            .filter((task) => task.delivery_method_guid === line.delivery_method.guid)
            .map((task) => [...task.relative_tasks.map((relativeTask) => relativeTask.guid)])
            .flat(),
        ];
      }
    });
    return selectedTasks;
  };

  handleLineProcessing = async (line: ILineChecked) => {
    const tasksIncludesInCoverageGuids = this.tasksIncludesInCoverageGuids(line);

    let routeDraft = this.routeDraftList.find((routeDraft) => routeDraft.lineGuid === line.guid);

    if (!routeDraft) {
      if (
        this.routeDraftList.length === 0 ||
        this.routeDraftList.every((routeDraft) => routeDraft.lineGuid !== line.guid)
      ) {
        routeDraft = {
          tasksList: [],
          isLoadingCard: false,
          isCalculated: false,
          coverages: [],
          uid: generateUid(),
          isPlanUploadTimeActive: Boolean(this.activeWarehouse?.config?.route_build),
          lineGuid: line.guid,
          lineName: line.name,
        };
        this.routeDraftList.unshift(routeDraft);
      }
    }

    try {
      const { data: courier } = await CouriersApi.getCourier(line.courier.guid);
      const { data: transport } = await TransportApi.getTransport(line.transport.guid);
      const { data: sessions } = await CouriersApi.getCourierSessions({
        current: 1,
        pageSize: 100,
        courier_guid: [line.courier.guid],
        planned_date: [dayjs(this.routeSettings.deliveryDate).format(dateFormat.search)],
      });
      const dateFormatted = this.routeSettings.deliveryDate
        ? this.routeSettings.deliveryDate.format(dateFormat.search)
        : null;
      const newSession = getSessionByDate(sessions.data, dateFormatted);
      this.setCourierDataRouteDraft(
        {
          ...routeDraft,
          fullName: getNameInitials(line.courier),
          courier: courier.data,
          transport: transport.data,
          sessionGuid: newSession?.guid,
          isPlanUploadTimeActive: routeDraft.isPlanUploadTimeActive,
          courierGuid: line.courier.guid,
          transportGuid: line.transport.guid,
        },
        routeDraft.uid,
      );
      this.moveSourceTasksToRoute(routeDraft.uid, tasksIncludesInCoverageGuids, false);
    } catch (e) {
      throw new RestException(e);
    }
  };

  addAutoRoutes = async () => {
    this.selectedTaskGuids = [];
    const selectedLines = this.linesListFilter.filter((line) => line.checked === true);

    try {
      await Promise.all(selectedLines.map((line) => this.handleLineProcessing(line)));
    } catch (error) {
      throw new Error(error);
    }
  };
}
