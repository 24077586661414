import { IProfile } from './types';

export const getFullName = (profile?: {
  name?: string;
  surname?: string;
  patronymic?: string;
}): string => {
  if (!profile) {
    return '';
  }
  return `${profile.surname ? profile.surname : ''} ${profile.name ? profile.name : ''} ${
    profile.patronymic ? profile.patronymic : ''
  }`.trim();
};

export const getNameInitials = (
  profile?: Pick<IProfile<string[]>, 'name' | 'patronymic' | 'surname'>,
): string => {
  const nameChar = profile.name ? profile.name.charAt(0) + '.' : '';
  const patronymicChar = profile.patronymic ? profile.patronymic.charAt(0) + '.' : '';
  return `${profile.surname} ${nameChar} ${patronymicChar}`;
};
