import './style.less';

import React from 'react';

import { ArrowRightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { observer } from 'mobx-react-lite';

import Navigation from 'modules/arm2/components/Navigation';
import { translate } from 'modules/localization';
import RouteGeneratorWorkspace from 'modules/routeGenerator/components/RouteGeneratorWorkspace';
import { GeneratorPageName } from 'modules/routeGenerator/models/types';
import { useStore } from 'services/store';
import RouteGeneratorCreateForm from 'src/modules/routeGenerator/components/RouteGeneratorCreateForm';

const RouteGeneratorPage = () => {
  const { routeGenerator } = useStore();

  if (routeGenerator.activePage === GeneratorPageName.FORM) {
    return (
      <>
        <Navigation />
        {routeGenerator.routeSettings.deliveryDate && (
          <div>
            <Button
              type="link"
              className="rg-page__backlink--right"
              onClick={() => routeGenerator.setActivePage(GeneratorPageName.GENERATOR)}
            >
              <ArrowRightOutlined /> {translate('toTheRouteGenerator')}
            </Button>
          </div>
        )}
        <div className="route-generator">
          <div className="route-generator-form-wrapper">
            <div className="route-generator-content">
              <div className="route-generator-title">{translate('parameters')}</div>
              <RouteGeneratorCreateForm />
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return <RouteGeneratorWorkspace />;
  }
};

export default observer(RouteGeneratorPage);
