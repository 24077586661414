import React from 'react';

import { Route, Switch } from 'react-router-dom';

import RoutesListPage from 'modules/routes/components/RoutesListPage';
import RouteViewPage from 'modules/routes/components/RoutesViewPage';

const Orders = () => {
  const baseUrl = '/arm2/routes';

  const renderRoute = (): React.ReactNode => <RouteViewPage baseUrl={baseUrl} />;

  const renderList = (): React.ReactNode => <RoutesListPage />;

  return (
    <Switch>
      <Route exact path={`${baseUrl}/:entityGuid`} render={renderRoute} />
      <Route exact path={baseUrl} render={renderList} />
    </Switch>
  );
};

export default Orders;
