import { Dayjs } from 'dayjs';

import {
  IApiResponseErrorMD2,
  IApiResponsePaginationMD2,
  IGenApiResponseMD2,
  IGenResponseAxios,
} from 'api/types';
import { ICourier } from 'modules/couriers/models/types';
import { ICoverage } from 'modules/coverages/models/types';
import { ILine } from 'modules/lines/models/types';
import { IRoute } from 'modules/routes/models/types';
import { ITask } from 'modules/tasks/models/types';
import { ITransport } from 'modules/transports/models/types';
import { IWarehouses } from 'modules/warehouses/models/types';

export enum GeneratorPageName {
  FORM,
  GENERATOR,
}

export interface ILineChecked extends ILine {
  checked?: boolean;
}

export interface IRouteSettings {
  warehouses: string[];
  deliveryDate: Dayjs;
  deliveryMethod: string[];
}

export interface ISavedRouteSettings extends Omit<IRouteSettings, 'deliveryDate'> {
  deliveryDate: Dayjs;
  version: string;
  createdAt: string;
}

export interface ISettingsData {
  courierGuid?: string;
  courier?: ICourier;
  sessionGuid?: string;
  time?: Dayjs;
  transportGuid?: string;
  fullName?: string;
  transport?: ITransport;
  planUploadTime?: Dayjs;
  planUploadDate?: Dayjs;
}

export interface ITaskForCompatibility {
  tasks: ITask[];
  error: string;
}

export interface ITasksGroupCoverage {
  tasks: ITask[];
}

export type ITasksGroupCoverages = Record<string, ITasksGroupCoverage>;

export interface IRouteDraft extends ISettingsData {
  tasksList: ITask[];
  isLoadingCard: boolean;
  isCalculated: boolean;
  uid: string;
  error?: string;
  start?: WarehousePreRoute;
  finish?: WarehousePreRoute;
  coverages: Partial<ICoverage>[];
  isHighlighted?: boolean;
  isScrollableIntoView?: boolean;
  isPlanUploadTimeActive: boolean;
  lineGuid?: string;
  lineName?: string;
}

interface ITaskRouteCreateForm {
  guid: string;
  planned_delivery_time: string;
}

export interface IRouteCreateForm {
  date_time_planned_finish: string;
  date_time_planned_start: string;
  session_guid: string;
  tasks: ITaskRouteCreateForm[];
  date_time_planned_upload: string;
}

export interface IRouteCreateFormYandex {
  task_guids: string[];
  courier_transport_bond: {
    courier_guid: string;
    transport_guid: string;
  }[];
}

export interface IRouteCalculateForm {
  courier_guid: string;
  task_guids: string[];
  transport_guid: string;
  route_start_time: string;
}

export interface WarehousePreRoute {
  datetime: string;
  warehouse: IWarehouses;
}

export interface ICalculatedPreRoute {
  start: WarehousePreRoute;
  finish: WarehousePreRoute;
  task_list: ITask[];
}

export interface ISaveRoutes {}

type IRouteCalculateApiResponse = IGenApiResponseMD2<
  ICalculatedPreRoute,
  IApiResponsePaginationMD2,
  IApiResponseErrorMD2
>;

type IRouteCreateApiResponse = IGenApiResponseMD2<
  IRoute,
  IApiResponsePaginationMD2,
  IApiResponseErrorMD2
>;

type IRoutesCreateApiResponse = IGenApiResponseMD2<
  ISaveRoutes,
  IApiResponsePaginationMD2,
  IApiResponseErrorMD2
>;

export type IRouteCalculateResponse = IGenResponseAxios<IRouteCalculateApiResponse>;
export type IRouteCreateResponse = IGenResponseAxios<IRouteCreateApiResponse>;
export type IRoutesCreateResponse = IGenResponseAxios<IRoutesCreateApiResponse>;
