import './style.less';

import React, { useEffect } from 'react';

import { Checkbox, Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';

import classSet from 'helpers/classSet';
import { ILineChecked } from 'modules/routeGenerator/models/types';
import { useStore } from 'services/store';

interface IProps {
  line: ILineChecked;
}

const LineCheckbox = ({ line }: IProps) => {
  const [countTasks, setCountTasks] = React.useState<number>(0);
  const { routeGenerator } = useStore();

  useEffect(() => {
    setCountTasks(routeGenerator.tasksIncludesInCoverageGuids(line).length);
  }, []);

  const onChange = (e: CheckboxChangeEvent, guid: string) => {
    routeGenerator.changeSelectLine(e.target.checked, guid);
  };

  let isCoverageSomeRepeat = false;
  const coverages = (
    <>
      {toJS(line.coverage_list).map((coverage, index) => {
        let isRepeat = false;
        if (routeGenerator.getCoveragesGuidLinesRepeat.includes(coverage.guid)) {
          isCoverageSomeRepeat = true;
          isRepeat = true;
        }
        return (
          <span
            key={coverage.guid}
            className={classSet({
              'rg-line-modal__coverage--repeat': isRepeat,
            })}
          >
            {coverage.name}
            {index < line.coverage_list.length - 1 && ', '}
          </span>
        );
      })}
    </>
  );

  return (
    <Checkbox checked={line.checked} onChange={(e) => onChange(e, line.guid)}>
      <div className="rg-line-modal__line">
        <div className="rg-line-modal__line-top">
          <div className="rg-line-modal__name">
            {line.name}{' '}
            <span
              className={classSet({
                'rg-line-modal__coverage--repeat': isCoverageSomeRepeat,
              })}
            >
              ({countTasks})
            </span>
          </div>
          <div className="rg-line-modal__courier">
            {`${line.courier.surname} ${line.courier.name[0]}. ${
              line.courier.patronymic ? line.courier.patronymic[0] + '.' : ''
            }`}
            ,{' '}
            <span className="rg-line-modal__courier--car">
              {line.transport.name} {line.transport.number}
            </span>
          </div>
        </div>
        <div className="rg-line-modal__line-bottom">
          <div className="rg-line-modal__line-bottom-ellipsis">
            <Typography.Paragraph
              ellipsis={{
                rows: 1,
                expandable: false,
                expanded: false,
                suffix: null,
                tooltip: coverages,
              }}
            >
              {coverages}
            </Typography.Paragraph>
          </div>
          <div className="rg-line-modal__delivery-method">{line.delivery_method.name}</div>
        </div>
      </div>
    </Checkbox>
  );
};

export default observer(LineCheckbox);
