import React from 'react';

import { observer } from 'mobx-react-lite';

import List from 'modules/directory/containers/List/List';
import { useStore } from 'services/store';

import { getColumns } from './helpers';

interface IProps {
  baseUrl: string;
}

const LinesList = ({ baseUrl }: IProps) => {
  const { lines } = useStore();

  return (
    <List
      baseUrl={baseUrl}
      actionListGet={lines.getList}
      list={lines.list}
      filter={lines.filter}
      getColumns={getColumns}
      isLoading={lines.isLoadingList}
      actionDelete={lines.delete}
    />
  );
};

export default observer(LinesList);
