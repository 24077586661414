import './style.less';

import React, { ReactElement } from 'react';

import { Route, Switch } from 'react-router-dom';

import Navigation from 'modules/arm2/components/Navigation';

import Form from '../Form';
import Header from '../Header';
import List from '../List';

const Lines = () => {
  const baseUrl = '/arm2/lines';

  const renderLayout = (): ReactElement => (
    <div className="lines">
      <div className="lines__header">
        <Navigation />
        <Header baseUrl={baseUrl} />
      </div>
      <List baseUrl={baseUrl} />
      <Form baseUrl={baseUrl} />
    </div>
  );

  return (
    <Switch>
      <Route exact path={`${baseUrl}/:entityGuid/:action`} render={renderLayout} />
      <Route exact path={`${baseUrl}/:action`} render={renderLayout} />
      <Route exact path={baseUrl} render={renderLayout} />
    </Switch>
  );
};

export default Lines;
