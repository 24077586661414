import React, { ReactNode, forwardRef, useEffect } from 'react';

import { Select, Spin } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { observer } from 'mobx-react-lite';
import { BaseSelectRef } from 'rc-select/lib/BaseSelect';

import { selectFilter } from 'helpers/form';
import { DEFAULT_SELECT_PROPS } from 'modules/common/components/Select';
import { ICourier } from 'modules/couriers/models/types';
import { translate } from 'modules/localization';
import { useStore } from 'services/store';

interface IProps {
  isSelectAllOptionAllowed?: boolean;
  warehouses?: string[];
  isSelectProfile?: boolean;
}

const CouriersSelect = forwardRef<BaseSelectRef, IProps & SelectProps>(
  ({ onChange, warehouses, isSelectProfile, ...rest }, ref) => {
    const { courierDirectory } = useStore();

    const getOptions = () => {
      const options = courierDirectory.listAll;
      if (options && Array.isArray(options)) {
        return options;
      }
      return [];
    };

    const fetchDefaultOptions = async () => {
      if (Array.isArray(warehouses) && warehouses.length > 0) {
        await courierDirectory.getAll(warehouses);
      }
    };

    useEffect(() => {
      fetchDefaultOptions();
    }, [warehouses && warehouses.join(',')]);

    const onChangeValue = (value, option): void => {
      const isAll = value && value.length && value.includes('all');
      const valueToSet = isAll ? getOptions().map((w) => w.guid) : value;
      onChange(valueToSet, option);
    };

    const renderOption = (option: ICourier): ReactNode => {
      if (option) {
        return (
          <Select.Option
            key={isSelectProfile ? option.profile.guid : option.guid}
            value={isSelectProfile ? option.profile.guid : option.guid}
          >
            {`${option.profile.surname} ${option.profile.name} ${
              option.profile.patronymic ? option.profile.patronymic : ''
            }${option.profile.disabled ? ` (${translate('disabled')})` : ''}`}
          </Select.Option>
        );
      }
      return null;
    };

    const options = getOptions();

    return (
      <Select
        ref={ref}
        showSearch
        allowClear
        loading={courierDirectory.isLoadingList}
        filterOption={selectFilter}
        getPopupContainer={(el) => el}
        onChange={onChangeValue}
        placeholder={translate('enterCourier')}
        notFoundContent={
          courierDirectory.isLoadingList ? <Spin size="small" /> : translate('nothingWasFound')
        }
        {...DEFAULT_SELECT_PROPS}
        {...rest}
      >
        {options.map(renderOption)}
      </Select>
    );
  },
);

export default observer(CouriersSelect);
