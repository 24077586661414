import Api from 'api/Api';
import { IFilter } from 'modules/arm2/models/types';
import {
  ILineCreateResponse,
  ILineEditResponse,
  ILineForm,
  ILineGetResponse,
  ILineListGetResponse,
  ILineDeleteResponse,
} from 'modules/lines/models/types';

export default class LineApi {
  static getLine(guid: string): Promise<ILineGetResponse> {
    return Api.get(`/api/v1/lines/${guid}`);
  }

  static getLineList(filter: IFilter, signal?: AbortSignal): Promise<ILineListGetResponse> {
    const query = {
      page: filter.current,
      page_size: filter.pageSize,
      warehouse_guid_list: filter.warehouses,
    };
    if (filter.order) {
      const sortQuery = `sort[${filter.columnKey}]`;
      query[sortQuery] = filter.order === 'ascend' ? 'asc' : 'desc';
    }
    return Api.get('/api/v1/lines', query, signal);
  }

  static createLine(data: ILineForm): Promise<ILineCreateResponse> {
    return Api.post(data, '/api/v1/lines');
  }

  static editLine(guid: string, data: ILineForm): Promise<ILineEditResponse> {
    return Api.put(data, `/api/v1/lines/${guid}`);
  }

  static deleteLine(guid: string): Promise<ILineDeleteResponse> {
    return Api.delete(`/api/v1/lines/${guid}`);
  }

  static searchLines(
    q: string,
    filter: IFilter,
    signal?: AbortSignal,
  ): Promise<ILineListGetResponse> {
    const query = {
      page: filter.current,
      page_size: filter.pageSize,
      q,
    };
    return Api.get('/api/v1/lines', query, signal);
  }
}
