import './style.less';

import React, { ReactNode, useEffect } from 'react';

import { Button, Form, Modal } from 'antd';
import { FormInstance } from 'antd/lib';
import HTTPStatus from 'http-status';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';

import { IFieldData, getFieldDataList, renderFields } from 'helpers/form';
import { ViewNotification } from 'modules/common/containers/Notify';
import { translate } from 'modules/localization';
import { routerStore } from 'services/store';

interface IMessage {
  editSuccess: string; // 'Сущность успешно изменена'
  createSuccess: string; // 'Сущность успешно создана'
  editTitle: string; // 'Редактирование сущности'
  createTitle: string; // 'Добавление сущности'
}

interface IParams {
  action: string;
  entityGuid: string;
}

interface IOwnProps {
  baseUrl: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actionGet: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actionCreate: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actionEdit: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getDataToSet: (values: any) => Record<string, any>;
  getFields: (isEdit: boolean, isCreate?: boolean) => IFieldData;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  prepareData: any;
  message: IMessage;
  isPermittedSubmit: boolean;
  isLoading?: boolean;
  renderFields?: (form: FormInstance) => ReactNode;
  modalClassName?: string;
}

type TProps = IOwnProps & RouteComponentProps<IParams>;

const DirectoryForm = (props: TProps) => {
  const { entityGuid, action } = props.match.params;
  const [form] = Form.useForm();
  const isEdit = action === 'edit';
  const isCreate = action === 'create';

  useEffect(() => {
    if (entityGuid) {
      props.actionGet(entityGuid).then((data) => {
        form.resetFields();
        form.setFieldsValue(props.getDataToSet(data));
      });
    } else {
      form.resetFields();
    }
  }, [action]);

  const actionReset = () => {
    routerStore.replace(`${props.baseUrl}${props.location.search}`);
  };

  const handleReset = (e) => {
    e.preventDefault();
    actionReset();
  };

  const handleResponse = (msg: string) => () => {
    actionReset();
    ViewNotification.success({ message: msg });
  };

  const handleError = (values) => (response) => {
    if (response.http.status === HTTPStatus.BAD_REQUEST) {
      const fieldDataList = getFieldDataList(response, values);
      form.setFields(fieldDataList);
    }
  };

  const submitClickHandler = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    form.submit();
  };

  const handleSubmit = (values): void => {
    const data = props.prepareData(values, isEdit);

    if (isEdit && entityGuid) {
      props
        .actionEdit(entityGuid, data)
        .then(handleResponse(props.message.editSuccess))
        .catch(handleError(values));
    } else {
      props
        .actionCreate(data)
        .then(handleResponse(props.message.createSuccess))
        .catch(handleError(values));
    }
  };

  const renderFooter = (): ReactNode => {
    return (
      <div className="dir-form__actions">
        <Button htmlType="button" key="cancel" onClick={handleReset}>
          {translate('cancel')}
        </Button>
        {props.isPermittedSubmit ? (
          <Button
            htmlType="submit"
            key="save"
            onClick={submitClickHandler}
            type="primary"
            loading={props.isLoading}
          >
            {isEdit ? translate('save') : translate('add')}
          </Button>
        ) : null}
      </div>
    );
  };

  const fieldsData = props.getFields(isEdit, isCreate);

  return (
    <Modal
      centered
      className={`dir-modal ${props.modalClassName ? props.modalClassName : ''}`}
      footer={renderFooter()}
      maskClosable={false}
      onCancel={handleReset}
      onOk={handleSubmit}
      title={isEdit ? props.message.editTitle : props.message.createTitle}
      open={isCreate || isEdit}
      width={null}
      destroyOnClose
    >
      <Form
        form={form}
        className="dir-form"
        layout="vertical"
        onFinish={handleSubmit}
        autoComplete="off"
        preserve={false}
      >
        {props.renderFields
          ? props.renderFields(form)
          : renderFields(Object.keys(fieldsData), fieldsData)}
      </Form>
    </Modal>
  );
};

export default withRouter(DirectoryForm);
