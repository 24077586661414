import { action, makeObservable, observable } from 'mobx';

import { RestException } from 'api/RestException';
import { DirectoryApi, DirectoryApiStore } from 'modules/common/store/DirectoryApiStore';
import {
  IWarehouses,
  IWarehousesPayload,
  IWarehousesSettings,
} from 'modules/warehouses/models/types';

import WarehousesApi from '../api/WarehousesApi';

const Api: DirectoryApi<IWarehouses, IWarehousesPayload> = {
  getEntity: WarehousesApi.getWarehouse,
  getEntityList: WarehousesApi.getWarehousesList,
  search: WarehousesApi.searchWarehouses,
  createEntity: WarehousesApi.createWarehouse,
  editEntity: WarehousesApi.editWarehouse,
  deleteEntity: WarehousesApi.deleteWarehouse,
};
export class WarehousesStore extends DirectoryApiStore<IWarehouses, IWarehousesPayload> {
  isLoadingSettingsForm = false;

  constructor() {
    super(Api, '/directories/warehouses');

    makeObservable(this, {
      isLoadingSettingsForm: observable,
      getWarehouseTZ: action.bound,
      editSettings: action.bound,
    });
  }

  editSettings = async (guid: string, data: IWarehousesSettings[]): Promise<void> => {
    try {
      this.isLoadingSettingsForm = true;
      await WarehousesApi.editWarehouseSettings(guid, data);
      this.setSearchQuery('');
      await this.getList({ ...this.filter, current: 1 });
      this.isLoadingSettingsForm = false;
    } catch (e) {
      this.isLoadingSettingsForm = false;
      throw new RestException(e);
    }
  };

  getWarehouseTZ = async (guid: string): Promise<string> => {
    const warehouse = this.listAll.find((w) => w.guid === guid);
    if (warehouse) {
      return warehouse.timezone;
    } else {
      const warehouse = await this.get(guid);
      return warehouse.timezone;
    }
  };
}
