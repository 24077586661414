import './style.less';

import React from 'react';

import { Button } from 'antd';
import { observer } from 'mobx-react-lite';

import { translate } from 'modules/localization';
import { IRouteDraft } from 'modules/routeGenerator/models/types';
import { isRouteHasError, isRouteReadyToCalculate } from 'modules/routeGenerator/models/utils';
import { useStore } from 'services/store';

const GeneratorRightSideCard = () => {
  const { routeGenerator, warehouses } = useStore();

  const routeId = routeGenerator.openCardRouteDraftId;
  const cardData: IRouteDraft = routeGenerator.getRouteDraft(routeId);

  const calculate = async () => {
    routeGenerator.switchIsLoadingCalculate(true);
    const tz = await warehouses.getWarehouseTZ(cardData.tasksList[0].warehouse_guid);
    await routeGenerator.apiStore.calculateRoute(routeId, tz).finally(() => {
      routeGenerator.switchIsLoadingCalculate(false);
    });
  };

  const save = async () => {
    routeGenerator.switchIsLoadingSave(true);
    await routeGenerator.apiStore
      .createCourierSession(routeId)
      .then(async () => {
        const tz = await warehouses.getWarehouseTZ(cardData.tasksList[0].warehouse_guid);
        routeGenerator.apiStore.saveRoute(routeId, tz).then(() => {
          routeGenerator.removeRoute(routeId);
          routeGenerator.apiStore.getTasksWrapperLoading();
        });
      })
      .finally(() => routeGenerator.switchIsLoadingSave(false));
  };

  if (cardData.isCalculated) {
    return (
      <div className="route-actions__button-group">
        <Button
          type="primary"
          onClick={save}
          disabled={
            isRouteHasError(cardData) ||
            routeGenerator.getIsLoadingCalculate ||
            routeGenerator.getIsLoadingCalculateYandex ||
            routeGenerator.isLoadingTasksList
          }
          loading={routeGenerator.getIsLoadingSave}
        >
          {translate('save')}
        </Button>
      </div>
    );
  }

  return (
    <div className="route-actions__button-group">
      <Button
        type="primary"
        onClick={calculate}
        disabled={
          !isRouteReadyToCalculate(cardData) ||
          routeGenerator.getIsLoadingSave ||
          routeGenerator.getIsLoadingCalculateYandex ||
          routeGenerator.isLoadingTasksList
        }
        loading={routeGenerator.getIsLoadingCalculate}
      >
        {translate('calculate')}
      </Button>
    </div>
  );
};

export default observer(GeneratorRightSideCard);
