import { ILine, ILineForm, ILinePayload } from 'modules/lines/models/types';

export const getDataToSet = (item: ILine): ILineForm => {
  return {
    name: item.name,
    warehouse_guid: item.warehouse
      ? {
          key: item.warehouse.guid,
          label: item.warehouse.title,
        }
      : undefined,
    courier_guid: item.courier
      ? {
          key: item.courier.guid,
          label: `${item.courier.surname} ${item.courier.name} ${
            item.courier.patronymic ? item.courier.patronymic : ''
          }`,
        }
      : undefined,
    transport_guid: item.transport
      ? {
          key: item.transport.guid,
          label: `${item.transport.name} ${item.transport.number}`,
        }
      : undefined,
    delivery_method_guid: item.delivery_method
      ? {
          key: item.delivery_method.guid,
          label: item.delivery_method.name,
        }
      : undefined,
    coverage_list: item.coverage_list
      ? item.coverage_list.map((m) => ({
          key: m.guid,
          label: m.name,
        }))
      : undefined,
  };
};

export const prepareData = (formData: ILineForm | Record<string, never>): ILinePayload => {
  return {
    name: formData.name ? formData.name.trim() : null,
    warehouse_guid: formData.warehouse_guid
      ? typeof formData.warehouse_guid === 'string'
        ? formData.warehouse_guid.trim()
        : formData.warehouse_guid.key.trim()
      : null,
    courier_guid: formData.courier_guid
      ? typeof formData.courier_guid === 'string'
        ? formData.courier_guid.trim()
        : formData.courier_guid.key.trim()
      : null,
    transport_guid: formData.transport_guid
      ? typeof formData.transport_guid === 'string'
        ? formData.transport_guid.trim()
        : formData.transport_guid.key.trim()
      : null,
    delivery_method_guid: formData.delivery_method_guid
      ? typeof formData.delivery_method_guid === 'string'
        ? formData.delivery_method_guid.trim()
        : formData.delivery_method_guid.key.trim()
      : null,
    coverage_list: formData.coverage_list
      ? formData.coverage_list.map((t) => (typeof t === 'string' ? t : t.key))
      : [],
  };
};
