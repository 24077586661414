import React from 'react';

import { observer } from 'mobx-react-lite';

import Title from 'modules/common/components/Title';
import ButtonCreate from 'modules/directory/components/ActionButtons/ButtonCreate';
import ButtonCreateMobile from 'modules/directory/components/ActionButtons/ButtonCreateMobile';
import Search from 'modules/directory/containers/Search';
import { translate } from 'modules/localization';
import { useStore } from 'services/store';

import WarehouseFilter from '../WarehouseFilter';

interface IProps {
  baseUrl: string;
}

const LinesHeader = ({ baseUrl }: IProps) => {
  const { core, lines } = useStore();

  return (
    <div className="dir__header">
      <Title className="dir__title" size={Title.SIZE.H3} weight={Title.WEIGHT.SEMIBOLD}>
        {translate('linesList')}

        {core.isMobile && (
          <ButtonCreateMobile baseUrl={baseUrl} isPermitted={core.permissions['lines.create']} />
        )}
      </Title>
      <div className="dir__header-section">
        <div className="dir__header-filters">
          <Search
            actionSearch={lines.search}
            actionListGet={lines.getList}
            actionSearchSet={lines.setSearchQuery}
            query={lines.searchQuery}
            placeholder={translate('searchByCourierOrName')}
          />
          <WarehouseFilter
            className="courier-filter__warehouse"
            getList={lines.getList}
            setFilter={lines.setFilter}
            value={lines.filter.warehouses}
            searchSet={lines.setSearchQuery}
          />
        </div>
        {!core.isMobile && (
          <ButtonCreate baseUrl={baseUrl} isPermitted={core.permissions['lines.create']}>
            {translate('addDirection')}
          </ButtonCreate>
        )}
      </div>
    </div>
  );
};

export default observer(LinesHeader);
