import './style.less';

import React, { useEffect, useRef } from 'react';

import { Table } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';

import { IPagination } from 'api/types';
import { IFilter } from 'modules/arm2/models/types';
import { getRowHandler } from 'modules/directory/helpers';
import { useStore } from 'services/store';

interface IProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actionListGet: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actionSearch?: any;
  baseUrl: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  list: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filter: IFilter;
  setFilter?: (key: string, value: string[] | boolean | number | string) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getColumns: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actionDelete?: any;
  isLoading?: boolean;
}

const List = (props: IProps) => {
  const { core } = useStore();

  const {
    baseUrl,
    actionListGet,
    actionSearch,
    list,
    filter,
    setFilter,
    getColumns,
    isLoading,
    actionDelete,
  } = props;
  const location = useLocation<Record<string, never>>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateList = ({ current, pageSize }: IPagination, _, sort: SorterResult<any>): void => {
    const url = new URL(window.location.href);
    const query = url.searchParams.get('q');

    query && actionSearch
      ? actionSearch(query, filter)
      : actionListGet({
          ...filter,
          current,
          pageSize,
          columnKey: String(sort.columnKey),
          order: sort.order,
        });
  };

  // componentDidMount
  useEffect(() => {
    const url = new URL(window.location.href);
    const query = url.searchParams.get('q');

    query && actionSearch ? actionSearch(query) : actionListGet();

    return () => {
      setFilter && setFilter('current', 1);
    };
  }, []);

  // render
  const columns = getColumns(baseUrl, core.permissions, actionDelete, filter);

  const listRef = useRef(null);
  const scrollIntoView = () => listRef.current.nativeElement.scrollIntoView({ behavior: 'smooth' });

  return (
    <Table
      ref={listRef}
      className="dir__list"
      dataSource={list}
      columns={columns}
      bordered={false}
      pagination={{
        ...filter,
        simple: core.isMobile,
        showSizeChanger: true,
        onChange: scrollIntoView,
      }}
      rowKey="guid"
      onChange={updateList}
      onRow={getRowHandler(baseUrl, location)}
      loading={isLoading}
    />
  );
};

export default observer(List);
