import { DirectoryApi, DirectoryApiStore } from 'modules/common/store/DirectoryApiStore';

import LinesApi from '../api/LinesApi';
import { ILine, ILineForm } from '../models/types';

const Api: DirectoryApi<ILine, ILineForm> = {
  getEntity: LinesApi.getLine,
  getEntityList: LinesApi.getLineList,
  createEntity: LinesApi.createLine,
  editEntity: LinesApi.editLine,
  deleteEntity: LinesApi.deleteLine,
  search: LinesApi.searchLines,
};
export class LinesStore extends DirectoryApiStore<ILine, ILineForm> {
  constructor() {
    super(Api, '/arm2/lines');
  }
}
