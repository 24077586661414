import './style.less';

import React, { ReactNode, forwardRef, useEffect, useState } from 'react';

import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { observer } from 'mobx-react-lite';
import { BaseSelectRef } from 'rc-select/lib/BaseSelect';
import { useParams } from 'react-router';

import { selectFilter } from 'helpers/form';
import { DEFAULT_SELECT_PROPS } from 'modules/common/components/Select';
import { ICoverage } from 'modules/coverages/models/types';
import { translate } from 'modules/localization';
import { useStore } from 'services/store';

interface IProps {
  location?: string;
  deliveryMethod?: string;
  allowAll?: boolean;
}

interface IParams {
  locationGuid: string;
}

const CoveragesSelect = forwardRef<BaseSelectRef, IProps & SelectProps>(
  ({ onChange, disabled, location, deliveryMethod, allowAll, ...rest }, ref) => {
    const { coverage, serviceZones } = useStore();

    const getOptions = () => {
      const options = coverage.coveragesList;
      if (options && Array.isArray(options)) {
        return options;
      }
      return [];
    };

    const [hoveredCoveragesBuffer, setHoveredCoveragesBuffer] = useState(
      serviceZones.hoveredCoverages,
    );

    const params = useParams<IParams>();
    const locationGuid = location ? location : params.locationGuid;

    const fetchDefaultOptions = async (): Promise<void> => {
      if (coverage.filter['locationGuid']) {
        await coverage.apiStore.getCoverages(false);
      }
    };

    useEffect(() => {
      serviceZones.loadingForm === false &&
        setHoveredCoveragesBuffer(serviceZones.hoveredCoverages);
    }, [serviceZones.loadingForm]);

    useEffect(() => {
      if (location) {
        coverage.setFilter('locationGuid', location);
        fetchDefaultOptions();
      }
    }, [location]);

    useEffect(() => {
      if (deliveryMethod) {
        coverage.setFilter('deliveryMethodGuid', deliveryMethod);
        fetchDefaultOptions();
      }
    }, [deliveryMethod]);

    useEffect(() => {
      coverage.resetFilter();
      coverage.setFilter('locationGuid', locationGuid);
      locationGuid && fetchDefaultOptions();

      return () => {
        coverage.setCoveragesList([]);
        coverage.setCoveragesListView([]);
      };
    }, []);

    const onChangeValue = (value, option): void => {
      const isAll = value && value.length && value.includes('all');
      const valueToSet = isAll ? [...getOptions()].map((o) => o.guid) : value;

      setHoveredCoveragesBuffer(valueToSet);
      serviceZones.setHoveredCoverages(valueToSet);
      onChange(valueToSet, option);
    };

    const renderOption = (option: ICoverage): ReactNode => {
      if (option) {
        return (
          <Select.Option
            key={option.guid}
            value={option.guid}
            onMouseEnter={(): void =>
              serviceZones.setHoveredCoverages([...hoveredCoveragesBuffer, option.guid])
            }
            onMouseLeave={(): void => serviceZones.setHoveredCoverages(hoveredCoveragesBuffer)}
          >
            {option.name || option.guid} {option.disabled ? ` (${translate('disabled')})` : ''}
          </Select.Option>
        );
      }

      return null;
    };

    const options = getOptions();

    return (
      <Select
        disabled={disabled}
        showSearch
        allowClear
        ref={ref}
        loading={coverage.isLoadingCoveragesList}
        filterOption={selectFilter}
        defaultActiveFirstOption={false}
        getPopupContainer={(el) => el}
        onChange={onChangeValue}
        className="coverage-select"
        placeholder={translate('enterCoverages')}
        mode="multiple"
        {...rest}
        {...DEFAULT_SELECT_PROPS}
      >
        {allowAll && (
          <Select.Option key="all" value="all">
            {translate('allCoverages')}
          </Select.Option>
        )}

        {options.map(renderOption)}
      </Select>
    );
  },
);

export default observer(CoveragesSelect);
