import './style.less';

import React, { useEffect, useState } from 'react';

import { ArrowLeftOutlined, PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { observer } from 'mobx-react-lite';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import Navigation from 'modules/arm2/components/Navigation';
import Title from 'modules/common/components/Title';
import { translate } from 'modules/localization';
import LinesModal from 'modules/routeGenerator/components/common/LinesModal';
import LeftSide from 'modules/routeGenerator/components/left/LeftSide';
import RightSide from 'modules/routeGenerator/components/right/RightSide';
import { GeneratorPageName } from 'modules/routeGenerator/models/types';
import { useStore } from 'services/store';

const RouteGeneratorWorkspace = () => {
  const [isLinesModalOpen, setIsLinesModalOpen] = useState(false);

  const { routeGenerator, deliveryMethods, tasks, warehouses, currency } = useStore();

  useEffect(() => {
    deliveryMethods.getList({ pageSize: 100, current: 1 }, false);
    tasks.apiStore.getTasksTypes();
    routeGenerator.apiStore.getTasksWrapperLoading();
    warehouses
      .get(routeGenerator.routeSettings.warehouses[0])
      .then((item) => routeGenerator.setActiveWarehouse(item));
    currency.getCurrency();

    return routeGenerator.setSearchFilterValue(null);
  }, []);

  return (
    <>
      <div className="rg-page">
        <DndProvider backend={HTML5Backend}>
          <div className="rg-page__leftside">
            <div className="rg-page__header">
              <Navigation />
              <Button
                type="link"
                className="rg-page__backlink"
                onClick={() => routeGenerator.setActivePage(GeneratorPageName.FORM)}
              >
                <ArrowLeftOutlined /> {translate('toTheSelectionOfParameters')}
              </Button>
              <div className="rg-page__title">
                <Title size={Title.SIZE.H3} weight={Title.WEIGHT.SEMIBOLD}>
                  {translate('routeGenerator')}
                </Title>
                <Button
                  icon={<PlusOutlined />}
                  onClick={() => setIsLinesModalOpen(true)}
                  disabled={routeGenerator.taskListSource.length === 0}
                >
                  {translate('lines')}
                </Button>
              </div>
            </div>
            <LeftSide />
          </div>

          <div className="rg-page__rightside">
            <RightSide />
          </div>
        </DndProvider>
      </div>
      <LinesModal isModalOpened={isLinesModalOpen} onCancel={() => setIsLinesModalOpen(false)} />
    </>
  );
};

export default observer(RouteGeneratorWorkspace);
