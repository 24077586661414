import React from 'react';

import Icon from '@ant-design/icons';

const CheckSvg = () => (
  <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.12473 9.76114C3.0966 9.76114 3.07004 9.75957 3.04191 9.75488C2.84816 9.72832 2.67941 9.61426 2.58254 9.44551L0.0934752 5.09082C-0.0783998 4.79082 0.0262876 4.40957 0.326288 4.2377C0.626288 4.06582 1.00754 4.17051 1.17941 4.47051L3.26066 8.11426L10.9325 0.418947C11.1763 0.175197 11.5716 0.173635 11.8169 0.417385C12.0607 0.661135 12.0622 1.05645 11.8185 1.30176L3.5966 9.54863L3.56691 9.57832C3.44816 9.69707 3.28879 9.76114 3.12473 9.76114Z"
      fill="#00CC66"
    />
  </svg>
);

export const CheckIcon = (props) => <Icon component={CheckSvg} {...props} />;

export const FilterSvg = () => (
  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 2.4C0 1.95817 0.366313 1.6 0.818182 1.6H17.1818C17.6337 1.6 18 1.95817 18 2.4C18 2.84183 17.6337 3.2 17.1818 3.2H0.818182C0.366313 3.2 0 2.84183 0 2.4Z"
      fill="#00CC66"
    />
    <path
      d="M0 8C0 7.55817 0.366313 7.2 0.818182 7.2H17.1818C17.6337 7.2 18 7.55817 18 8C18 8.44183 17.6337 8.8 17.1818 8.8H0.818182C0.366313 8.8 0 8.44183 0 8Z"
      fill="#00CC66"
    />
    <path
      d="M0 13.6C0 13.1582 0.366313 12.8 0.818182 12.8H17.1818C17.6337 12.8 18 13.1582 18 13.6C18 14.0418 17.6337 14.4 17.1818 14.4H0.818182C0.366313 14.4 0 14.0418 0 13.6Z"
      fill="#00CC66"
    />
    <path
      d="M3.27273 1.6C3.27273 0.716344 4.00535 0 4.90909 0H6.54545C7.44919 0 8.18182 0.716344 8.18182 1.6V3.2C8.18182 4.08366 7.44919 4.8 6.54545 4.8H4.90909C4.00535 4.8 3.27273 4.08366 3.27273 3.2V1.6Z"
      fill="#00CC66"
    />
    <path
      d="M9 7.2C9 6.31634 9.73263 5.6 10.6364 5.6H12.2727C13.1765 5.6 13.9091 6.31634 13.9091 7.2V8.8C13.9091 9.68366 13.1765 10.4 12.2727 10.4H10.6364C9.73263 10.4 9 9.68366 9 8.8V7.2Z"
      fill="#00CC66"
    />
    <path
      d="M3.27273 12.8C3.27273 11.9163 4.00535 11.2 4.90909 11.2H6.54545C7.44919 11.2 8.18182 11.9163 8.18182 12.8V14.4C8.18182 15.2837 7.44919 16 6.54545 16H4.90909C4.00535 16 3.27273 15.2837 3.27273 14.4V12.8Z"
      fill="#00CC66"
    />
  </svg>
);

export const FilterIcon = (props) => <Icon component={FilterSvg} {...props} />;

export const CrossSvg = () => (
  <svg width="12" height="12" xmlns="http://www.w3.org/2000/svg">
    <path
      clipRule="evenodd"
      d="m6.87,6l3.98,-3.98c0.24,-0.24 0.24,-0.63 0,-0.87c-0.24,-0.24 -0.63,-0.24 -0.87,0l-3.98,3.98l-3.98,-3.98c-0.24,-0.24 -0.63,-0.24 -0.87,0c-0.24,0.24 -0.24,0.63 0,0.87l3.98,3.98l-3.98,3.98c-0.24,0.24 -0.24,0.63 0,0.87c0.12,0.12 0.28,0.18 0.43,0.18c0.16,0 0.32,-0.06 0.44,-0.18l3.98,-3.98l3.98,3.98c0.13,0.12 0.28,0.18 0.44,0.18c0.16,0 0.31,-0.06 0.43,-0.18c0.24,-0.24 0.24,-0.63 0,-0.87l-3.98,-3.98z"
      fill="#BFBFBF"
      fillRule="evenodd"
    />
  </svg>
);

export const RemoveIcon = (props) => (
  <Icon component={CrossSvg} {...props} style={{ height: '22px' }} />
);

export const ClearIcon = (props) => <Icon component={CrossSvg} {...props} />;

export const ArrowUpSvg = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_23656_103151)">
      <path
        d="M8.4 6.40521e-07H11.6C11.7061 6.40521e-07 11.8078 0.0421434 11.8828 0.117158C11.9579 0.192172 12 0.293914 12 0.400001V3.6C12.0001 3.67922 11.9768 3.75669 11.9328 3.8226C11.8889 3.88851 11.8263 3.93989 11.7532 3.97023C11.68 4.00056 11.5995 4.00848 11.5218 3.99299C11.4441 3.9775 11.3727 3.93929 11.3168 3.8832L10 2.5656L0.683199 11.8832L0.116799 11.3168L9.4344 2L8.1168 0.683201C8.06071 0.627257 8.0225 0.55592 8.00701 0.478233C7.99152 0.400546 7.99944 0.320009 8.02977 0.24683C8.06011 0.173651 8.11149 0.111127 8.1774 0.0671845C8.24331 0.0232416 8.32078 -0.000141092 8.4 6.40521e-07Z"
        fill="#8C8C8C"
      />
    </g>
    <defs>
      <clipPath id="clip0_23656_103151">
        <rect width="12" height="12" fill="white" transform="matrix(-1 0 0 1 12 0)" />
      </clipPath>
    </defs>
  </svg>
);

export const ArrowUpIcon = (props) => (
  <Icon component={ArrowUpSvg} {...props} style={{ height: '12px' }} />
);
